import React from "react";
import AppComponent from '../AppComponent';
import Content from '../Content';
import CustomTabs from "../CustomTabs";

import { Spin, Tabs, Divider, Alert, Collapse, Table} from 'antd';

const TabPane = Tabs.TabPane;


const questionDict = {
    "GOING_WELL_COMMENT": "Please name three things that are going well in the course.",
    "IMPROVEMENT_COMMENT": "Please name three things that could be improved in the course.",
    "TA_COMMENT": "Please comment on your teaching assistants for this course.",
    "ADDITIONAL_THOUGHTS": "Please provide any additional thoughts that you would like to share about this course."
}


class FacultyEvals extends AppComponent {
  state = {
    endpoint_evals: "/api/faculty/midtermevals/",
    evals: [],
    loading_evals: true,

    endpoint_sections: "/api/schedule/",
    sections: [],
    loading_sections: true,
    loading_sections_staff: true,

    formattedResponses: {}
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.semester !== this.props.semester) {
      this.setState({
        sections: [],
        formattedResponses: {},
        evals: [],
        loading_evals: true,
        loading_sections: true, 
        loading_sections_staff: true
      }, () => {
        this.getData();
      });
    }
  }

  formatResponses = (evals) => {

    const sectionResponses = {};

    evals?.forEach((ev) => {
      const sectionID = ev.section;
      if (!sectionResponses[sectionID]) {
        sectionResponses[sectionID] = {
          "GOING_WELL_COMMENT": [],
          "IMPROVEMENT_COMMENT": [],
          "TA_COMMENT": [],
          "ADDITIONAL_THOUGHTS": []
        };
      }
      ev.questions.forEach((q) => {
          if (sectionResponses[sectionID][q.question]) {
              sectionResponses[sectionID][q.question].push(q.response);
          }
      });
    });
    return sectionResponses;
  }

  getCourseEvals = () => {
      this.doGet(
        this.state.endpoint_evals + "?semester=" + this.props.semesters[0],
        (data) => {
          this.setState(
            {
              evals: data,
              loading_evals: false
            },
            () => {
              const formattedResponses = this.formatResponses(this.state.evals); 
              this.setState({ formattedResponses });
            }
          );
        }
      );
    };

  getSections = () => {
    this.doGet(this.state.endpoint_sections + "?semester=" + this.props.semesters.join(",") + "&instructors=" + this.props.user.person.id,
    data => this.setState({ sections: data , loading_sections: false }));

    this.doGet(this.state.endpoint_sections + "?semester=" + this.props.semesters.join(",") + "&instructor_staff=" + this.props.user.person.id,
    data => this.setState({ sections: [...this.state.sections, ...data] , loading_sections_staff: false }));
  }

  getData = () => {
    // only get section data and course eval data if Fall or Spring semester
    if (this.props.semesters.length == 1) {
      this.getSections();
      this.getCourseEvals();
    } else {
      this.setState({ loading_evals: false, loading_sections: false, loading_sections_staff: false })
    }
  }

  renderTabs = () => {
    const { formattedResponses, sections } = this.state;

    return (
      <CustomTabs {...this.props}>
        {sections.map((section) => (
          <TabPane
            key={section.id}
            tab={[this.print_section(section)]}
          >
            {formattedResponses[section.id] ? (
              <>
                <Divider orientation="left">
                  Below are the questions students answered in their Midterm Evaluations. Click on each question to expand and view their responses.                </Divider>
                <Collapse>
                  {Object.keys(formattedResponses[section.id]).map(
                    (question_type) => (
                      <Collapse.Panel
                        header={questionDict[question_type]}
                        key={question_type}
                      >
                        <Table
                          dataSource={formattedResponses[section.id][question_type].map((response, index) => ({
                            key: index,
                            response: response,
                          }))}
                          columns={[
                            {
                              
                              dataIndex: 'response',
                              key: 'response',
                              render: (text) => <div style={{ whiteSpace: "pre-line" }}>{text}</div>,
                            }
                          ]}
                          pagination={{ pageSize: 10 }}
                          showHeader={false} 
                        />
                      </Collapse.Panel>
                    )
                  )}
                </Collapse>
              </>
            ) : (
              <Alert message="There are currently no evaluations for this section." />
            )}
          </TabPane>
        ))}
      </CustomTabs>
    )
  }


  render() {
    const { loading_evals, loading_sections, loading_sections_staff, sections } = this.state;

    return (
      <Content
        {...this.props}
        title={"Midterm Course Evaluations for " + this.props.semester}
        breadcrumbs={[
          { link: "/teaching", text: "Teaching" },
          { text: "Midterm Course Evaluations" },
        ]}
      >
        <p>Welcome to the new midterm course evaluations response form. This site is designed for faculty to review responses from midterm evaluations for each of the Khoury courses they are teaching that semester. Please note that reviews are only available once the review window for students has closed and that student responses have not been reviewed by staff. Please reach out to the Senior Associate Dean of Academic Programs and Student Experience if you notice any concerning comments or have any questions.</p>
        {this.props.semesters.length === 1 ? (
          // Render content if Fall or Spring is selected
          <>
            {loading_evals || loading_sections || loading_sections_staff ? (
              <Spin tip="Loading course data" />
            ) : (
              <>
                
                {sections.length > 0 ? (
                  // If sections are found, render tabs. 
                  this.renderTabs()
                ) : (
                  <Alert message="No sections found for this semester." type="warning" showIcon />
                )}
              </>
            )}
          </>
        ) : (
          // If the semester is not Fall or Spring, show the summer semester alert
          <Alert
            message="Midterm course evaluations are not conducted during summer semesters."
            type="info"
            showIcon
          />
        )}
      </Content>
    );
}}
  
export { FacultyEvals };
  