import React, { Component } from "react";
import moment from "moment";
import key from "weak-key";
import { Link } from "react-router-dom";
import NumberFormat from 'react-number-format';
import AppComponent from './AppComponent';
import Content from './Content';
import SectionTable from './SectionTable';
import { CSVLink } from "react-csv";
import { isEmpty, oxford, get_check_nocheck, add_brs, format_nuid } from './Utils';

import { PageHeader, Alert, Table, Form, Switch, Layout, Tooltip, Icon, Menu, Dropdown, Spin, Calendar, Col, Statistic, Button, Row, List, Card, Divider, Badge, Modal, Breadcrumb, Checkbox, message, Input } from 'antd';
const FormItem = Form.Item;
const { TextArea } = Input;

class DescriptionTable extends AppComponent {
  render() {
    const { section, crosslist, loading, mygroup } = this.props;

    return loading ? "" : (
      <React.Fragment>
        <b>Course</b>: {this.link_course(section.course)} {section.title ? <i>{section.title}</i> : this.print_course_title(section.course)}<br />
        <b>Instructor</b>: {oxford(section.instructors.map(el => this.link_instructor(el)))}<br />
        <b>CRN</b>: {section.crn}<br />
        <b>Meeting time</b>: {this.print_meetingtime(section.meetingtime)}<br />
        <b>Room</b>: {this.link_full_room(section.room)}<br />
        <b>Enrolled</b>: {section.enrollment + "/" + section.capacity}&nbsp;<Tooltip title="Cached data from our nightly crawl" ><Icon type="question-circle" /></Tooltip><br />
        <b>Waitlist</b>: {section.waitlist_capacity ? section.waitlist_enrollment + "/" + section.waitlist_capacity : "N/A"}<br />
        <b>Crosslist</b>: {crosslist ? this.print_section(crosslist) : <i>None</i>}<br />
        <b>Section Group</b>: {mygroup ? <Link key={mygroup.id} to={this.getLink("/teaching/schedules/sectiongroup/" + mygroup.id)}>{mygroup.name}</Link> : <i>None</i>}
      </React.Fragment>
    );
  }
}

class GradeTable extends AppComponent {
  render() {
    const { section, loading } = this.props;

    const columns = [
      {
        title: 'Grade',
        key: 'grade',
        render: (text, record, idx) => this.print_grade(record.id),
      }, {
        title: 'Count',
        key: 'count',
        align: 'right',
        render: (text, record, idx) => section ? section.grades[this.get_grade(record.id).grade] : "",
      }, {
        title: 'Passing',
        key: 'passing',
        render: (text, record, idx) => get_check_nocheck(this.get_grade(record.id).passing),
      }
    ];

    return <Table {...this.props} dataSource={this.grade_list()} loading={loading} columns={columns} bordered={false} pagination={false} size="small" rowKey={(record) => record.id} />;
  }
}

class TeamTable extends AppComponent {
  render() {
    const { teams, loading } = this.props;

    const columns = [
      {
        title: 'Name',
        key: 'name',
        render: (text, record, idx) => record.name,
      }, {
        title: 'Sections',
        key: 'sections',
        render: (text, record, idx) => add_brs(record.sections.map(this.print_section)),
      }
    ];

    return <Table {...this.props} dataSource={teams} loading={loading} columns={columns} bordered={false} pagination={false} size="small" rowKey={(record) => record.id} />;
  }
}

const TeamForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

    render() {
      const { visible, onCancel, onCreate, form, section, crosslist, schedule } = this.props;
      const { getFieldDecorator } = form;

      const format_section = (s) => {
        return { label: [this.print_section(s)].concat([", ", oxford(s.instructors.map(this.print_instructor)), " ", s.id == section.id ? <i>(this course)</i> : crosslist && s.id == crosslist.id ? <i>(crosslist)</i> : ""]), value: s.id, disabled: s.id == section.id };
      }

      var options = [];

      if (section) {
        const others = schedule.filter(s => (s.id != section.id) && (crosslist == null || s.id != crosslist.id));
        others.sort(this.section_comparator);

        options = [section];
        if (crosslist) {
          options = options.concat([crosslist]);
        }
        options = options.concat(others).map(format_section);
      }

      return (
        <Modal visible={visible} title="Team Request" okText="Request" onCancel={onCancel} onOk={onCreate} width={800} closable={false}>
          <Form>
            <p>Please use the form below to request that a Microsoft Teams "Team" be created for your section.  If you wish to create a "merged" Team that spans multiple sections (e.g., for coordinated classes, or for cross-listed classes), select the listed sections below.</p>
            <p>When a Team is created, by default, all instructors, coordinators, and staff coordinators will be added as Owners of the team, and all IAs, TAs and enrolled students will be added as Members.  We will periodically refresh the membership to ensure that students who drop are removed from the Team, and those that enroll are added (you should not manually add anyone to the Team, as they may be removed by our script).</p>
            <p><b>Note:</b> It will take up to 24 hours for your Team to be created.  It will show up in your Teams client once it is created (and you should receive an email from Microsoft Teams).</p>

            <FormItem {...this.formItemLayout} label="Sections" extra="Please select the sections that should be used to create the Team.">
              {getFieldDecorator('sections', {
                rules: [{ required: true, message: 'Please select at least one of the sections.' }],
                initialValue: section ? [section.id] : [],
              })(<Checkbox.Group options={options} />)}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

const GroupForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

    render() {
      const { visible, onCancel, onCreate, form, section, crosslist, schedule, groups } = this.props;
      const { getFieldDecorator } = form;

      const format_section = (s) => {
        return { label: [this.print_section(s)].concat([", ", oxford(s.instructors.map(this.print_instructor)), " ", s.id == section.id ? <i>(this course)</i> : crosslist && s.id == crosslist.id ? <i>(crosslist)</i> : ""]), value: s.id, disabled: s.id == section.id || groups.find(g => g.sections.find(gs => gs.id == s.id)) };
      }

      var options = [];

      if (section) {
        const others = schedule.filter(s => (s.id != section.id) && (crosslist == null || s.id != crosslist.id));
        others.sort(this.section_comparator);

        options = [section];
        if (crosslist) {
          options = options.concat([crosslist]);
        }
        options = options.concat(others).map(format_section);
      }

      return (
        <Modal visible={visible} title="Section Group Request" okText="Request" onCancel={onCancel} onOk={onCreate} width={800} closable={false}>
          <Form>
            <p>Please use the form below to request that a section group be created.  If you wish to create a group that spans multiple sections (e.g., for coordinated classes, or for cross-listed classes), select the listed sections below.</p>
            <p>When you request a section group be created, students will be allowed to apply as TAs to your section group, rather than applying to the course/campus.  Similarly, TA assignments will be made to your section group independently of the other sections.</p>
            <p><strong>Note:</strong> We will append the course number to the front of the section group name.</p>
            {!isEmpty(this.props.tas) && (<><Alert message="Warning" type="warning" showIcon description="There are already TAs assigned to this section. 
            If you create a section group, these TAs will be disassociated and will have to be manually added back. Please reach out to khoury-admin@ccs.neu.edu."/><br /></>)}
            <FormItem {...this.formItemLayout} label="Section Group Name" extra="Please provide a meaningful section group name. Leaving this blank will show a default name of section number(s) and instructor name(s).">
              {getFieldDecorator('name', {
                initialValue: "",
              })(<Input addonBefore={section ? this.print_course(section.course) : ""} style={{ width: 390 }} rows={1} />)}
            </FormItem>
            <FormItem {...this.formItemLayout} label="Sections" extra="Please select the sections that should be used to create the section group.">
              {getFieldDecorator('sections', {
                rules: [{ required: true, message: 'Please select at least one of the sections.' }],
                initialValue: section ? [section.id] : [],
              })(<Checkbox.Group options={options} />)}
            </FormItem>
            <FormItem {...this.formItemLayout} label="TA Notes" extra="Note shown to students when applying to TA positions. Markdown supported.">
              {getFieldDecorator('TA_notes', {
                initialValue: "",
              })(<TextArea style={{ width: 390 }} rows={4} />)}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

class Section extends AppComponent {
  state = {
    endpoint: "/api/schedule/section/",
    section: null,
    loading: true,

    crosslist: null,
    loading_crosslist: true,

    endpoint_teams: "/api/schedule/team/",
    teams: null,
    loading_teams: true,

    endpoint_schedule: "/api/schedule/",
    schedule: [],
    loading_schedule: true,

    endpoint_groups: "/api/schedule/group/",
    groups: [],
    loading_groups: true,

    endpoint_taapplication: "/api/ta/application/",
    taapplications: [],
    loading_taapplications: true,

    newteam_visible: false,
    newgroup_visible: false,
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.doGet(this.state.endpoint + this.props.match.params.section_id + "/", data => this.setState({ section: data, loading: false }, () => this.getOtherData()));
  }

  getOtherData = () => {
    const { section } = this.state;
  
    this.getTeams();
    this.getGroups();
  
    if (section.crosslist) {
      // Get crosslist data, then get the schedule and TA data
      this.doGet(
        this.state.endpoint + section.crosslist + "/",
        (data) => {
          this.setState(
            { crosslist: data, loading_crosslist: false },
            () => {
              this.getSchedule();
              this.getTAData();
            }
          );
        }
      );
    } else {
      // No crosslist, so fetch the schedule and TA data right away
      this.setState({ loading_crosslist: false }, () => {
        this.getSchedule();
        this.getTAData(); // Call getTAData directly since there's no crosslist
      });
    }
  };

  getTAData = () => {
    const { section, endpoint_taapplication, crosslist } = this.state;
  
    // Initial query string
    let queryString = `${endpoint_taapplication}?semester=${section.semester}&state=Assigned,Accepted,Hire-Submitted,EIB,I-9-incomplete,Processed,Hired&course=${section.course}`;
  
    // If crosslist exists, append it to the crosslist course number to query
    if (crosslist) {
      queryString += `,${crosslist.course}`;
    }
  
    this.doGet(
      queryString,
      (data) => data
        ? this.setState({ taapplications: data, loading_taapplications: false })
        : this.setState({ loading_taapplications: false })
    );
  };
 
  getSchedule = () => {
    const { section, crosslist } = this.state;
    this.doGet(this.state.endpoint_schedule + "?semester=" + section.semester + "&deleted=False&course=" + section.course + (crosslist ? "," + crosslist.course : ""),
      data => this.setState({ schedule: data, loading_schedule: false }));
  }

  getTeams = () => {
    const { section } = this.state;
    this.doGet(this.state.endpoint_teams + "?semester=" + section.semester + "&sections=" + this.props.match.params.section_id, data => this.setState({ teams: data, loading_teams: false }));
  }

  getGroups = () => {
    const { section } = this.state;
    this.doGet(this.state.endpoint_groups + "?semester=" + section.semester, data => this.setState({ groups: data, loading_groups: false }));
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  saveFormRefGroup = (formRef) => {
    this.formRefGroup = formRef;
  }

  handleCreateTeam = () => {
    const { section } = this.state;
    const form = this.formRef.props.form;

    form.validateFields((err, values) => {
      if (err) { return; }

      values.semester = section.semester;
      values.name = "Ignore";
      this.doPost(this.state.endpoint_teams + "new/", () => { message.success("Requested new Team."); form.resetFields(); this.setState({ newteam_visible: false }, this.getTeams()); }, JSON.stringify(values));
    });
  }

  handleCreateGroup = () => {
    const { section } = this.state;
    const form = this.formRefGroup.props.form;

    form.validateFields((err, values) => {
      if (err) { return; }

      values.name = values.name ? this.print_course(section.course) + " " + values.name : values.name;
      values.semester = section.semester;
      this.doPost(this.state.endpoint_groups + "new/", () => { message.success("Requested new group."); form.resetFields(); this.setState({ newgroup_visible: false }, this.getGroups()); }, JSON.stringify(values));
    });
  }

  truncateDisplayedEmail = (email) => {
    return email && email.length > 29 ? email.substring(0, 26) + "..." : email;
  }

  render() {
    const { loading, section, taapplications, loading_taapplications, loading_teams, teams, loading_schedule, schedule, loading_crosslist, crosslist, newteam_visible, newgroup_visible, loading_groups, groups } = this.state;

    const all_loading = loading || loading_taapplications || loading_teams || loading_schedule || loading_crosslist || loading_groups;

    const title = loading ? "Section " + this.props.match.params.section_id : this.print_semester(section.semester) + " " + this.print_course(section.course) + " section " + section.number + " (CRN " + section.crn + ")";

    const alert = section ? moment(this.get_semester(section.semester).enddate, "YYYY-MM-DD") <= moment() ? (<Alert message="Semester already ended" description={"The " + this.print_semester(section.semester) + " semester has already ended, so you cannot request any new Teams or Section Groups be created."} type="warning" showIcon />) : null : null;

    const mygroup = groups.find(g => g.sections.find(s => s.id == this.props.match.params.section_id));
    const filtered_taapplications = mygroup ? taapplications.filter(el => el.group == mygroup.id) : taapplications.filter(el => el.campus == section.campus);

    const chars = [
      { title: "Basics", content: (<><DescriptionTable {...this.props} loading={all_loading} section={section} crosslist={crosslist} mygroup={mygroup} alert={alert} /><p />{alert ? <>{alert}<p /></> : null}<Button style={{ float: 'right' }} icon="plus" type="primary" disabled={all_loading || alert || mygroup} onClick={() => this.setState({ newgroup_visible: true })}>Request Section Group</Button></>) },
      { title: "Grades", content: (<GradeTable {...this.props} loading={all_loading} section={section} />) },
      { title: "Teams", content: (<><TeamTable {...this.props} loading={all_loading} teams={teams} /><p />{alert ? <>{alert}<p /></> : null}<Button style={{ float: 'right' }} icon="plus" type="primary" disabled={all_loading || alert} onClick={() => this.setState({ newteam_visible: true })}>Request Team</Button></>) },
    ];

    const enrolled = section ? section.students.filter(s => s.status == "active") : [];
    const dropped = section ? section.students.filter(s => (s.status == "dropped") || (s.status == "withdrawn")) : [];

    return (
      <Content {...this.props} title={title} breadcrumbs={[{ link: "/teaching", text: "Teaching" }, { link: "/teaching/schedules", text: "Schedules" }, { link: "/teaching/schedules", text: this.props.semester }, { text: all_loading ? "Section " + this.props.match.params.section_id : this.print_course(section.course) + " CRN " + section.crn }]}>
        <Divider orientation="left">Characteristics</Divider>
        <List
          grid={this.grid}
          dataSource={chars}
          renderItem={item => (<List.Item><Card size="small" title={item.title}>{item.content}</Card></List.Item>)}
        />
        {section && section.ias && section.ias?.length > 0 ? (
          <React.Fragment>
            <Divider orientation="left">Assigned IAs ({section.ias.length})</Divider>
            <List
              grid={this.grid_photos}
              dataSource={section.ias}
              renderItem={item => (<List.Item><Card size="small" title={this.print_full_student(item) + " (" + format_nuid(item.nuid) + ")"}><div className="student-photo"><img src={item.photo_url} /></div>{item.email ? <div><a href={"mailto:" + item.email}><Icon type="mail" /> {this.truncateDisplayedEmail(item.email)}</a></div> : null}</Card></List.Item>)}
            />
          </React.Fragment>
        ) : null}
        <Divider orientation="left">Assigned TAs ({filtered_taapplications.length})</Divider>
        <p>Below is the list of TAs who are currently assigned to this course (though not necessarily to your section).  If you need to, you can send an <a href={"mailto:" + filtered_taapplications.map(s => s.ta.email).join(",")}>email all of the TAs</a>. </p>
        <List
          grid={this.grid_photos}
          dataSource={filtered_taapplications}
          renderItem={item => (<List.Item><Card size="small" title={this.print_full_student(item.ta) + " (" + format_nuid(item.ta.nuid) + ")"}><div className="student-photo"><img src={item.ta.photo_url} /></div>{item.ta.email ? <div><a href={"mailto:" + item.ta.email}><Icon type="mail" /> {this.truncateDisplayedEmail(item.ta.email)}</a></div> : null}</Card></List.Item>)}
        />
        <Divider orientation="left">Enrolled Students ({enrolled.length})</Divider>
        <p>Below is the list of students who are currently enrolled in this section.  {section ? <>If you need to, you can send an <a href={"mailto:?bcc=" + section.students.filter(s => s.status == "active").map(s => s.email).join(",")}>email</a> or <CSVLink filename={this.print_section(section) + "-" + this.print_semester(section.semester) + ".csv"} data={section.students.filter(s => s.status == "active").map(({ rnum, id, photo_url, isremote, nuflex, phdstudent, ...item }) => item)}>download</CSVLink> data on all enrolled students.</> : null}</p>
        <List
          grid={this.grid_photos}
          dataSource={enrolled}
          renderItem={item => (<List.Item><Card size="small" title={this.print_full_student(item) + " (" + item.nuid + ")"}><div className="student-photo"><img src={item.photo_url} /></div>{item.email ? <div><a href={"mailto:" + item.email}><Icon type="mail" /> {this.truncateDisplayedEmail(item.email)}</a></div> : null}</Card></List.Item>)}
        />
        <Divider orientation="left">Withdrawn/Dropped Students ({dropped.length})</Divider>
        <p>Below is the list of students who were enrolled, but who have dropped this section.  {section ? <>If you need to, you can send an <a href={"mailto:?bcc=" + section.students.filter(s => s.status == "dropped").map(s => s.email).join(",")}>email all dropped students</a>.</> : null}</p>
        <List
          grid={this.grid_photos}
          dataSource={dropped}
          renderItem={item => (<List.Item><Card size="small" title={this.print_full_student(item) + " (" + item.nuid + ")"}><div className="student-photo"><img src={item.photo_url} /></div>{item.email ? <div><a href={"mailto:" + item.email}><Icon type="mail" /> {this.truncateDisplayedEmail(item.email)}</a></div> : null}</Card></List.Item>)}
        />
        <TeamForm {...this.props} section={section} schedule={schedule} crosslist={crosslist} wrappedComponentRef={this.saveFormRef} visible={newteam_visible} onCancel={() => this.setState({ newteam_visible: false })} onCreate={this.handleCreateTeam} teams={teams} />
        <GroupForm {...this.props} section={section} schedule={schedule} crosslist={crosslist} tas={filtered_taapplications} wrappedComponentRef={this.saveFormRefGroup} visible={newgroup_visible} onCancel={() => this.setState({ newgroup_visible: false })} onCreate={this.handleCreateGroup} groups={groups} />
      </Content>
    );
  }
}

export default Section;