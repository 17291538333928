import React from "react";
import AppComponent from "../../AppComponent";
import { isEmpty } from "../../Utils";
import { OfficeHoursButton } from "../../ExportDataToApps";
import Content from "../../Content";

import { Form, Input, Select, Button, message, Alert } from "antd";

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

const TAAccessRequestForm = Form.create({ name: "form_not_in_modal" })(
  class extends AppComponent {
    state = {
      endpoint: "/api/tempaccess/ta/",
      submitted: false,
    };

    handleSubmit = () => {
      const { endpoint } = this.state;
      const { form } = this.props;

      form.validateFields((err, values) => {
        if (err) { return; }

        const data = values;
        this.doPost(endpoint, (data) => {
            if (data) {
              message.success("Your request has been submitted.");
              this.setState({ submitted: true });
            }
          },
          JSON.stringify(data)
        );
      });
    };

    render() {
      const { submitted } = this.state;
      const { form, ta_requests } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 } }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }, colon: true, };

      return (
        <Content {...this.props} title={"TA Access Request"} breadcrumbs={[{ link: "/students", text: "Students" }, { text: "TA Access Request" }]}>
          <p>If you require more access than is provided to students that are non-Khoury Major and Minors, including access to the TA Application Form, please fill out the form below.</p>
          <div>
            { submitted ? (
              <Alert message="We will process your request. You may not submit a request of this kind again for 90 days." type="info" />
            ) : !isEmpty(ta_requests) ? (
              <Alert message="You have requested access to TA applications within the last 90 days. If approved, you'll receive an email notification and can re-login. If not approved, please wait until the next cycle to apply again." type="error" />
            ) : (
              <Form>
                <>
                  <p>
                    Teaching Assistant positions for Khoury classes are generally reserved for Khoury students, and requests are
                    only accepted in exceptional cases. If you believe you have such a case, please submit the form below.
                  </p>
                  <p>
                    Please provide a justification for accessing the Teaching Assistant portal. You can submit one request
                    every 90 days. If approved, you'll receive an email notification and can re-login. If you continue to see the{" "}
                    <i> We are unable to determine your affiliation to Khoury </i> {" "} screen after receiving an email, your request was denied.
                  </p>
                  <FormItem {...formItemLayout} label="Reasoning" className="login-left" extra="Please provide context for your request.">
                    {getFieldDecorator("justification", { rules: [{ required: true, message: "Please provide context." }] })
                      (
                        <TextArea style={{ width: 360 }} rows={4} />
                      )}
                  </FormItem>
                  <Button type="primary" onClick={this.handleSubmit}>Submit</Button>
                </>
              </Form>
            )}
          </div>
        </Content>
      );
    }
  }
);

class TAAccessRequest extends AppComponent {
  state = {
    endpoint: "/api/tempaccess/ta/",
    loading_requests: true,
    ta_requests: [],
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.doGet(this.state.endpoint, (data) => this.setState({ ta_requests: data, loading_requests: false }));
  };

  render() {
    const { ta_requests, loading_requests } = this.state;
    return (
      <>
        {loading_requests ? <></> : <TAAccessRequestForm {...this.props} ta_requests={ta_requests} />}
      </>
    );
  }
}

export { TAAccessRequest, TAAccessRequestForm };
