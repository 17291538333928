import React from "react";
import AppComponent from '../AppComponent';
import Content from '../Content';
import CustomTabs from "../CustomTabs";

import { Select, Spin, Tabs, Button, Divider, Form, message, Input, Alert, Popconfirm } from 'antd';
import { get_check, get_nocheck, manageStateItems } from "../Utils";

const FormItem = Form.Item;
const TabPane = Tabs.TabPane;
const { Option } = Select;
const { TextArea } = Input;

const EvalForm = Form.create({ name: 'form_not_in_modal' })(
  class extends AppComponent {


    handleSubmit = () => {
      const { handleCreateUpdate, selectedSection } = this.props;
      const form = this.props.form;

      form.validateFields((err, values) => {
        if (err) { return; }

        const formattedQuestions = Object.entries(values.questions)
          .filter(([question, response]) => response && response.trim() !== "")
          .map(([question, response]) => ({ question, response }));

        const data = {
          questions: formattedQuestions,
          section: selectedSection,
          semester: this.props.semesters[0]
        };

        handleCreateUpdate(() => { form.resetFields(); }, data);
      });
    };

    render() {
      const { form } = this.props;
      const { getFieldDecorator } = form;

      const allBlank = form.getFieldsValue().questions ? Object.values(form.getFieldsValue().questions).every(value => !value) : true;
      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

      return (
        <Form>
          <FormItem {...formItemLayout}
            label="Question 1"
            extra="Please name three things that are going well in the course">
            {getFieldDecorator('questions.GOING_WELL_COMMENT', {
              rules: [{ required: false, message: 'Please provide an answer.' }],
            })(<TextArea rows={4} style={{ width: 360 }} />
            )}
          </FormItem>
          <FormItem {...formItemLayout}
            label="Question 2"
            extra="Please name three things that could be improved in the course">
            {getFieldDecorator('questions.IMPROVEMENT_COMMENT', {
              rules: [{ required: false, message: 'Please provide an answer.' }],
            })(<TextArea rows={4} style={{ width: 360 }} />
            )}
          </FormItem>
          <FormItem {...formItemLayout}
            label="Question 3"
            extra="Please comment on your teaching assistants for this course.">
            {getFieldDecorator('questions.TA_COMMENT', {
              rules: [{ required: false, message: 'Please provide an answer.' }],
            })(<TextArea rows={4} style={{ width: 360 }} />
            )}
          </FormItem>
          <FormItem {...formItemLayout}
            label="Question 4"
            extra="Please provide any additional thoughts that you would like to share about this course.">
            {getFieldDecorator('questions.ADDITIONAL_THOUGHTS', {
              rules: [{ required: false, message: 'Please provide an answer.' }],
            })(<TextArea rows={4} style={{ width: 360 }} />
            )}
          </FormItem>
          <FormItem {...formItemLayout} label=" " colon={false}>
            <Popconfirm
              title="Once submitted, you will not be able to make changes. Are you sure you want to proceed with your submission?"
              onConfirm={() => this.handleSubmit()}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" disabled={allBlank}>{"Submit"}</Button>
            </Popconfirm>
          </FormItem>
        </Form>
      )
    };

  })

class StudentEvals extends AppComponent {
  state = {
    endpoint_create_eval: "/api/student/midtermevaluation/",

    endpoint_evals: "/api/student/midtermevaluation/status/",
    evals: [],
    loading_evals: true,

    form_open: true,
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.semester != this.props.semester) {
      this.setState({ loading_evals: true }, () => this.getData());
    }
  }

  getCourseEvals = () => {
    this.doGet(this.state.endpoint_evals + "?semester=" + this.props.semesters[0],
      data => this.setState({ evals: data.evals, form_open: data.form_open, loading_evals: false }));
  }

  getData = () => {
    // only get data if Fall or Spring semester
    if (this.props.semesters.length == 1) {
      this.getCourseEvals();
    } else {
      this.setState({ loading_evals: false })
    }
  }

  markEvalComplete(section) {
    const updatedEvals = this.state.evals.map((e) => e.id === section ? { ...e, complete: true } : e );
    this.setState({ evals: updatedEvals });
  }

  handleCreateUpdate = (callback, data) => {
    this.doAction(this.state.endpoint_create_eval, data, "add", (updatedData) => {
      if (updatedData){ this.markEvalComplete(updatedData.section); }
    });
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  getTabIcon = (complete) => {
    return complete ? get_check() : get_nocheck();
  }

  render() {
    const { evals, loading_evals, form_open } = this.state;

    // If there are evals to sort, sort them
    if (form_open && evals.length > 0) {
      evals?.sort((a, b) => {
        // Sort by the 'complete' status
        if (a.complete !== b.complete) {
          return a.complete - b.complete;
        }

        // If both have the same 'complete' status, sort alphabetically
        return a.name.localeCompare(b.name);
      });
    }

    return (
      <Content
        {...this.props}
        title={"Midterm Course Evaluation for " + this.props.semester}
        breadcrumbs={[
          { link: "/student", text: "Student" },
          { text: "Midterm Course Evaluations" },
        ]}
      >
        {this.props.semesters.length === 1 ? (
          // Render content if Fall or Spring is selected
          <>
            {loading_evals ? (
              // Show loading spinner while evaluations are being loaded
              <Spin tip="Loading course data" />
            ) : form_open ? (
              // If evaluations are visible
              <>
                {evals?.length > 0 ? (
                  // If evaluations exist, show the tabs with evaluation forms
                  <CustomTabs {...this.props}>
                    {evals?.map((e) => (
                      <TabPane
                        key={e.id}
                        tab={[
                          this.getTabIcon(e.complete),
                          " ",
                          e.name,
                        ]}
                      >
                        <Divider orientation="left">
                          Instructor(s): {e.instructor.join(" and ")}
                        </Divider>
                        {e.complete === false ? (
                          // If the evaluation hasn't been submitted, show form
                          <EvalForm
                            {...this.props}
                            handleCreateUpdate={this.handleCreateUpdate}
                            wrappedComponentRef={this.saveFormRef}
                            selectedSection={e.id}
                          />
                        ) : (
                          // If the evaluation has already been submitted
                          <Alert message="Thank you for your submission." />
                        )}
                      </TabPane>
                    ))}
                  </CustomTabs>
                ) : (
                  // If there are no evaluations
                  <Alert message="No courses to evaluate." type="warning" showIcon />
                )}
              </>
            ) : (
              // If evaluations are not visible (review period closed)
              <Alert message="The review period for this semester is closed." type="warning" showIcon />
            )}
          </>
        ) : (
          // If the semester is not Fall or Spring, show the summer semester alert
          <Alert
            message="Midterm course evaluations are not conducted during summer semesters."
            type="info"
            showIcon
          />
        )}
      </Content>
    );
  }
}

export { StudentEvals };
