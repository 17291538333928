import React, { Component } from "react";
import { Link } from "react-router-dom";
import key from "weak-key";
import NumberFormat from 'react-number-format';
import AppComponent from './AppComponent';
import SectionTable from './SectionTable';
import Content from './Content';
import { add_dividers, if_empty, add_brs, get_merit_tag, get_nonecheck, get_pausecheck, get_check } from "./Utils";

import {  Table, Spin, Form, message, DatePicker, Card,  Button, Select } from 'antd';

const FormItem = Form.Item

class TodoCard extends AppComponent {
    state = {
      endpoint: "/api/todo/",
      todos: [],
      loading: true,
    }
  
    componentDidMount() {
      this.getData()
    }
  
    getData = () => {
      this.doGet(this.state.endpoint, data => this.setState({ todos: data, loading: false }));
    }
  
    findSemester = (sem) => {
      const { semesters_list_grouped } = this.props;
      return Object.keys(semesters_list_grouped).find(s => semesters_list_grouped[s].find(ss => ss.id == sem));
    }
  
    render() {
      const { loading, todos } = this.state;
      const { workflows } = this.props;
  
      const columns_load = [
        {
          title: 'Todo',
          key: 'todo',
          render: (text, record, idx) => record.abstract_workflow_type ? [get_nonecheck(), " ", <Link to={this.getLink("/employee/workflow/", { workflow_item_id: record.id })}>{ }{record.name}</Link>] : [record.completed ? get_check() : record.started ? get_pausecheck() : get_nonecheck(), " ", record.link ? (<Link to={this.getLink(record.link)} onClick={() => record.semester ? this.props.onSetSemester(this.findSemester(record.semester)) : null}>{ }{record.title}</Link>) : record.title, [record.semester ? [" (", this.print_semester(record.semester), ")"] : null]],
        }, {
          title: 'Due',
          key: 'due',
          align: 'right',
          render: (text, record, idx) => record.abstract_workflow_type ? null : moment(record.due).format("MMM DD, YYYY"),
        }
      ];
  
      return (
        <Card size="small" title={"Your todos"}>
          {loading ? (<Spin tip="Loading todos..." />) : (<Table {...this.props} dataSource={workflows ? todos.concat(workflows) : todos} columns={columns_load} bordered={false} pagination={false} size="small" rowKey={(record) => record.abstract_workflow_type ? record.id : record.title} />)}
        </Card>);
    }
  }
  
  const TodoCreate = Form.create({ name: 'form_not_in_modal' })(
    class extends AppComponent {
      formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };
      dateFormat = 'YYYY-MM-DD';
  
      state = {
        endpoint: "/api/todo/",
  
        semester: null,
        mytype: null,
        visible: null,
        due: null,
        hide: null,
      }
  
      handleSubmit = (e) => {
        const form = this.props.form;
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
          if (!err) {
            values["due"] = values["due"] ? moment(values["due"]).format(this.dateFormat) : null;
            values["visible"] = values["visible"] ? moment(values["visible"]).format(this.dateFormat) : null;
            values["hide"] = values["hide"] ? moment(values["hide"]).format(this.dateFormat) : null;
  
            this.doPost(this.state.endpoint, response => { message.success("Created todo"); form.resetFields(); this.setState({ semester: null, mytype: null, visible: null, due: null, hidden: null }) }, JSON.stringify(values));
          } else {
            message.error(err);
          }
        });
      }
  
      disabledDate = (value) => {
        return moment().subtract(1, "day") > value.valueOf();
      }
  
      render() {
        const { getFieldDecorator } = this.props.form;
  
        const semester_list = this.semester_list().filter(el => el.code >= this.get_semester(this.props.semesters[0]).code).reverse();
        const types = ["PhD student review", "PhD student self review", "PhD student funding survey", "Teaching preferences", "Committee preferences", "Buyout review", "Rate TA applications", "Accept TA position", "Review TAs", "Complete TT/T merit review", "Complete teaching merit retrospective memorandum"];
  
        return (
          <Content {...this.props} title={"Todo Create"} breadcrumbs={[{ link: "/site-admin", text: "Admin" }, { text: "Todos" }]}>
            <p>Below is a form for creating todos for users.  To use it, select the type of todo you wish to create, the semester you wish to create it for, and the visible date, due date, and hidden date.</p>
  
            <Form onSubmit={this.handleSubmit} >
              <FormItem {...this.formItemLayout} label="Type" extra={"Select the kind of todo this is."}>
                {getFieldDecorator('mytype', {
                  rules: [{ required: true, message: 'Please select the type of todo.' }],
                  onChange: (event) => { this.setState({ mytype: event }) },
                })(<Select style={{ width: 360 }}>
                  {types.map(el => <Option key={el} value={el}>{el}</Option>)}
                </Select>
                )}
              </FormItem>
              <FormItem {...this.formItemLayout} label="Semester" extra={"Select the semester this todo is for."}>
                {getFieldDecorator('semester', {
                  rules: [{ required: true, message: 'Please select a semester.' }],
                  onChange: (event) => { this.setState({ semester: event }) },
                })(<Select style={{ width: 360 }}>
                  {semester_list.map(el => <Option key={el.id} value={el.id}>{this.print_semester(el.id)}</Option>)}
                </Select>
                )}
              </FormItem>
              <FormItem {...this.formItemLayout} label="Visible Date" extra={"Select the date the todo will start to be shown (defaults to now)."} >
                {getFieldDecorator('visible', {
                  onChange: (event) => { this.setState({ visible: event }) },
                })(<DatePicker format={this.dateFormat} disabledDate={this.disabledDate} />)}
              </FormItem>
              <FormItem {...this.formItemLayout} label="Due Date" extra={"Select the date the todo needs to be completed by."} >
                {getFieldDecorator('due', {
                  rules: [{ required: true, message: 'Please select the due date.' }],
                  onChange: (event) => { this.setState({ due: event }) },
                })(<DatePicker format={this.dateFormat} disabledDate={this.disabledDate} />)}
              </FormItem>
              <FormItem {...this.formItemLayout} label="Hidden Date" extra={"Select the date the todo will no longer be shown (defaults to the due date)."} >
                {getFieldDecorator('hide', {
                  onChange: (event) => { this.setState({ hide: event }) },
                })(<DatePicker format={this.dateFormat} disabledDate={this.disabledDate} />)}
              </FormItem>
              <FormItem {...this.formItemLayout} wrapperCol={{ xs: { span: 24, offset: 0 }, sm: { span: 16, offset: 8 }, }} >
                <Button type="primary" htmlType="submit">{"Create"}</Button>
              </FormItem>
            </Form>
          </Content>
        );
      }
    }
  );

export { TodoCard, TodoCreate }