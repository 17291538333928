import React, { Component } from "react";
import AppComponent from "../../../AppComponent";
import moment from "moment-timezone";
import { WorkflowSubmit } from "../../WorkflowSubmit";
import { WorkflowTable } from "../../WorkflowTable";
import { CourseWaiverForm } from "../GenericForms";
import Transcript from "../../../Transcript";

import { Icon, Radio, Steps, Table, Upload, Form, Input, Divider, Select, Button, InputNumber, message, Switch, Typography, Modal, Alert, Descriptions } from "antd";
import { isEmpty, oxford } from '../../../Utils';

const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { Text } = Typography;
const { Step } = Steps;


class PhDCourseWaiver extends AppComponent {

  getCurrentAdvisors = (current_code, record) => {
    const current_advisors = record.advisors.find(a => this.get_semester(a.start).code <= current_code && (a.end == null || this.get_semester(a.end).code >= current_code));
    return current_advisors ? oxford(current_advisors.advisors.map(e => this.print_instructor(e))) : <i>None</i>; 
  }

  render() {
   const { record } = this.props;
   const item = record.record;

   let course_petition = item.course_petition;
   let waiverTypes =  course_petition.waiver_types.map(el => el.waiver_type);

    return (
      <>
        <Descriptions bordered title={ "PhD Course Waiver submission by " + record.created_by} column={2} style={{ marginBottom: '20px' }}>
          <Descriptions.Item label="Submitted">{ moment(record.created_at).format("MMM DD, YYYY HH:mm") }</Descriptions.Item>
          <Descriptions.Item label="Requestor">{[ record.created_by + " ", <a key="email" href={"mailto:" + record.email}><Icon type="mail" theme="twoTone" /></a> ]}</Descriptions.Item>
          <Descriptions.Item label="NUID">{ item.phdstudent.nuid }</Descriptions.Item>
          { this.permission("can", "staff") && 
          <Descriptions.Item label="">{<Transcript {...this.props} nuid={item.phdstudent.nuid} />}</Descriptions.Item> }
          <Descriptions.Item label="Degree">{ item.phdstudent.degree ? this.print_degree(item.phdstudent.degree): "Couldn't load" }</Descriptions.Item>
          <Descriptions.Item label="Campus">{ this.print_campus(item.phdstudent.campus) }</Descriptions.Item>
          <Descriptions.Item label={<p>Advisor <small>(*at submission)</small></p>}>{ this.getCurrentAdvisors(this.find_semester(record.created_at), item.phdstudent) }</Descriptions.Item>
          <Descriptions.Item label="Matriculated">{ item.phdstudent.matriculated ? this.print_semester(item.phdstudent.matriculated) : "Couldn't load"  }</Descriptions.Item>
          <Descriptions.Item label="Core Class">{this.print_course(course_petition.khoury_course)}</Descriptions.Item>
          <Descriptions.Item label="Course Waiver Type">{ item.course_waiver_type }</Descriptions.Item>
          { item.advanced_entry && <Descriptions.Item label="Advanced Entry">{ item.advanced_entry ? "Yes" : "No" }</Descriptions.Item>}
          <Descriptions.Item label="Waiver Type">{  waiverTypes.join(", ") }</Descriptions.Item>
          {!isEmpty(record.files) && <Descriptions.Item label="Attached Files">{[record.files.map(el => <><a onClick={ () => this.openPDF(el.attachment, el.file_type +".pdf") }><Icon type="file-pdf" theme="twoTone" twoToneColor="#eb2f96" />{el.file_type +".pdf"}</a><br /></>) ]}</Descriptions.Item>}
        </Descriptions>
          {course_petition.non_khoury_courses.map((el, idx) => (
            <>
            <Descriptions bordered title={"Non-Khoury Course Information " + (idx + 1)} column={2} key={idx} style={{ marginBottom: '20px' }}>
              <Descriptions.Item label="Course Name">{ el.course }</Descriptions.Item>,
              <Descriptions.Item label="University / College">{ el.university }</Descriptions.Item>,
              <Descriptions.Item label="Credits" >{ el.credits }</Descriptions.Item>,
              <Descriptions.Item label="Semester" >{ this.print_semester(el.semester) }</Descriptions.Item>,
              <Descriptions.Item label="Instructor Name">{ el.instructor_name }</Descriptions.Item>,
              <Descriptions.Item label="Instructor Email">{ <a key="email" href={"mailto:" + el.instructor_email }>{el.instructor_email}</a>}</Descriptions.Item>,
              <Descriptions.Item label="Course Grade">{ el.grade ? this.print_grade(el.grade): "N/A" }</Descriptions.Item>,
              <Descriptions.Item label="Graduate Level">{ el.course_level? "Yes": "No" }</Descriptions.Item>,
              <Descriptions.Item label="Count towards another degree?" span={ 2 }>{ el.course_degree? "Yes": "No" }</Descriptions.Item>,
              <Descriptions.Item label="Notes" span={2}>{ el.rationale }</Descriptions.Item>
          </Descriptions>
          </>
          ))}
          { waiverTypes.includes("Experience") && 
          <Descriptions bordered title={"Relevant Work Experience"} style={{ marginBottom: '20px' }}>
            <Descriptions.Item label="Rationale">{ course_petition.work_experience }</Descriptions.Item>
          </Descriptions>
          }
          <br></br>
      </>

      );
   }
}


class PhDCourseWaiverOverview extends WorkflowSubmit {
  state = {
    endpoint: "/api/petition/phd/coursepetition/",
    visible: true,
  }

  getData = () => {
    this.setState({ loading: true }, () => this.doGet(this.state.endpoint, data => { this.setState({ data: data, loading: false }) }));
  }

  get_name = () => {
    return "PhD Course Waiver";
  }

  get_workflowtype = () => {
    return "phdcoursepetition";
  }

  get_name_plural = () => {
    return "PhD Course Waiver";
  }     
  
  get_breadcrumbs = () => {
    return [{ link: "/student", text: "Students" }, { text: "phd" }, { text: "Course Waiver" }];
  }
  
  get_form = (func) => {
    return <CourseWaiverForm {...this.props} formType={"phd"} onSubmit={ this.submit_form } />;
  }
  
  get_record_view = (record) => {
    return <PhDCourseWaiver {...this.props} record={ record } />
  }
  
  submit_form = (data, func) => {
    this.doPost(this.state.endpoint, () => { this.getData(); func ? func() : null; }, JSON.stringify(data));
  }
  
  get_overview_text = () => {
    return (
      <p>Use this form to request to waive a course requirement based on prior coursework or experience. If approved, you may take elective coursework instead of required core coursework. The total number of semester hours required to complete the PhD program is not affected; Advanced Entry students must complete at least 16 SH of approved coursework, and regular entry students must complete at least 48 SH of approved coursework.

      Please submit one form per request; if you would like to waive multiple courses, please submit multiple Course Waivers.</p>
    );
  }  
}

export { PhDCourseWaiverOverview, PhDCourseWaiver };