import React, { Component } from "react";
import AppComponent from "../../AppComponent";
import { WorkflowTable } from "../WorkflowTable";
import moment from "moment-timezone";
import Transcript from "../../Transcript";

import { Descriptions, Icon } from "antd";


class TATempAccessRequest extends AppComponent {
  render() {
    const { record } = this.props;
    const item = record.record;

    return (
      <Descriptions bordered title={"Temporary Access Requests"}>
        <Descriptions.Item label="Requestor" span={ 2 }>{record.created_by}</Descriptions.Item>
        {/* <Descriptions.Item label="Requestor" span={ 2 }>{record.created_by}</Descriptions.Item> */}
        { this.permission("can", "staff") && 
          <Descriptions.Item label="" span={ 1 }>{<Transcript {...this.props} nuid={item.nuid} />}</Descriptions.Item> 
        }
        <Descriptions.Item label="Email" span={ 2 }>{[ record.email + " ", <a key="email" href={"mailto:" + item.neu_email}><Icon type="mail" theme="twoTone" /></a> ]}</Descriptions.Item>
        <Descriptions.Item label="NUID" span={ 1 }>{item.nuid}</Descriptions.Item>
        <Descriptions.Item label="Submitted" span={ 1 }>{moment(record.created_at).format("MMM DD, YYYY HH:mm")}</Descriptions.Item>
        <Descriptions.Item label="Reasoning" span={ 3 }>{item.justification}</Descriptions.Item>
      </Descriptions>
    );
  }
}

class TATempAccessRequestTable extends WorkflowTable {
  get_columns = () => {
    return [{
        title: "NUID",
        width: 80,
        render: (text, record, idx) => record.record.nuid
      }, {
        title: "Email",
        width: 200,
        render: (text, record, idx) => ( <a key="email" href={"mailto:" + record.record.neu_email}>{record.record.neu_email}</a> )
      }];
  }
}
  

export { TATempAccessRequest, TATempAccessRequestTable };
