import React from "react";
import AppComponent from "../AppComponent";
import Content from "../Content";
import { Table, Form, Divider, Select, Input, Button, message, Spin, Modal, Alert } from "antd";
import StudentCreateForm from "../Student/StudentForms";

const { TextArea } = Input;
const FormItem = Form.Item;
const { Option } = Select;

const ApprenticeNominationForm = Form.create({ name: "form_not_in_modal" })(
  class extends AppComponent {
    state = {
      students: [],
      endpoint_students: "/api/student/",
      loading_students: true,
      // controlling what parts of the form to show
      skip_visible: false,
      add_student: false,
    }

    componentDidMount() {
      const { student } = this.props;
      if (student) {
        this.setState({ student: student, skip_visible: true });
      }
    }

    loadStudents = (v) => {
      const { endpoint_students, students } = this.state;
      if (v) {
        this.setState(
          { loading_students: true, students: [] },
          () => this.doGet(endpoint_students + (v ? "?search=" + v : ""),
            data => this.setState({ students: data, loading_status: false })
          ))
      }
    }

    handleSubmit = e => {
      const { student } = this.state;
      const { form, endpoint, semesters, addNewSubmission } = this.props;
      e.preventDefault();
      form.validateFields((err, values) => {
        if (err) { return; }

        !values["student"] && student ? values["student"] = student.id : null;
        const data = { ...values, "semester": semesters[0] };

        this.doPost(endpoint, (data) => {
          if (data) {
            message.success("Your nomination has been submitted.");
            this.formReset();
            addNewSubmission(data);
          }
        },
          JSON.stringify(data));
      })
    };


    formReset = () => {
      this.setState({ student: "", skip_visible: false, add_student: false });
      this.props.form.resetFields();
    }

    closeAddStudent = (data) => {
      this.setState({ student: data, add_student: false });
    }

    render() {
      const { form, numberOfNoms, semester, semesters, viewform } = this.props;
      const { getFieldDecorator } = form;
      const { students, loading_students, add_student, student } = this.state;
      const disableSubmit = numberOfNoms >= 5;
      const nominatedStudentIDs = viewform.map(nom => nom.student.id)

      const formItemLayout = {
        labelCol: { xs: { span: 24 }, sm: { span: 8 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
        colon: true
      };


      return (
        <Form onSubmit={this.handleSubmit}>
          <>
            {(!add_student && !student) ? (
              <FormItem {...formItemLayout} label="Student" extra={"Start typing to search and select a student. For most accurate results, enter a Northeastern email address. If there is no match, click the Add Student button above."}>
                <>
                  {getFieldDecorator("student", { rules: [{ required: !add_student, message: "Please select a student" }], initialValue: "" })
                    (
                      <Select showSearch autocomplete="off" style={{ width: 360 }} filterOption={this.filter} onSearch={this.loadStudents} placeholder="Type here to search" notFoundContent={loading_students ? <Spin size="small" /> : null} >
                        {students.map(u =>
                          <Option key={u.id} value={u.id} disabled={nominatedStudentIDs.includes(u.id)}>
                            {this.print_full_student(u)} ({u.email})
                          </Option>)}
                      </Select>
                    )}
                  {" "}
                  <Button onClick={() => this.setState({ add_student: true })}>Add Student</Button>
                </>
              </FormItem>) : null}
            {(!add_student && student) && (
              <FormItem {...formItemLayout} label="Student"><p>{this.print_full_student(student)}</p></FormItem>
            )}
            {add_student ? (
              <StudentCreateForm {...this.props} completeHook={this.closeAddStudent} />
            ) : (
              <>
                <FormItem {...this.formItemLayout}
                  label="Semester"
                  extra={"The semester can be changed in the upper right hand corner. Please ensure that the selected semester reflects when the apprenticeship will take place."}
                  colon={false}
                >
                  {getFieldDecorator('semester', {
                    initialValue: semester,
                  })
                    (<Select showSearch style={{ width: 220 }} filterOption={this.filter} disabled={true} >
                      <Option value={semester}>{semester}</Option>
                    </Select>
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label="Reasoning" extra={"Please provide a brief explanation of your nomination"} colon={false}>
                  {getFieldDecorator("reasoning", {
                    rules: [{ required: true, message: "Please provide a short answer." }], initialValue: ""
                  })
                    (<TextArea style={{ width: 360 }} rows={2} />)}
                </FormItem>
              </>
            )}

            <FormItem {...this.formItemLayout} label=" " colon={false}>
              {!add_student && (<Button type="primary" htmlType="submit" disabled={disableSubmit}>Submit</Button>)}
            </FormItem>
          </>
        </Form>
      );
    }
  }
);

const ApprenticeNominationEdit = Form.create({ name: "form_in_modal" })(
  class extends AppComponent {
    state = {
      students: [],
      endpoint_students: "/api/student/",
      loading_students: true,
    }


    handleSubmit = e => {
      const { form, endpoint, updateExistingSubmission, recordId, toggleModal } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        if (!err) {
          const data = values;
          this.doPatch(endpoint + recordId.toString() + "/", (data) => {
            updateExistingSubmission(data);
            message.success("Your updated nomination has been submitted.");
            toggleModal(recordId, false);
            form.resetFields();
          },
            JSON.stringify(data));
        } else {
          message.error(err);
        }
      });
    };

    loadStudents = (v) => {
      const { endpoint_students, students } = this.state;
      if (v) {
        this.setState(
          { loading_students: true, students: [] },
          () => this.doGet(endpoint_students + (v ? "?search=" + v : ""),
            data => this.setState({ students: data, loading_students: false })
          ))
      }
    }

    render() {
      const { form, nomReasoning, semester } = this.props;
      const { getFieldDecorator, } = form;

      const formItemLayout = {
        labelCol: { xs: { span: 24 }, sm: { span: 8 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
        colon: true
      };

      return (
        <>
          <FormItem {...formItemLayout} label="Reasoning" extra={"Please provide a brief explanation of your nomination"} colon={false}>
            {getFieldDecorator("reasoning", {
              rules: [{ required: true, message: "Please provide a short answer." }], initialValue: nomReasoning
            })
              (<TextArea style={{ width: 360 }} rows={2} />)}
          </FormItem>
          <FormItem {...this.formItemLayout} label=" " colon={false}>
            <Button type="primary" htmlType="submit" onClick={this.handleSubmit} >Submit</Button>
          </FormItem>
        </>
      );

    };
  });

class ApprenticeNomination extends AppComponent {

  state = {
    endpoint: "/api/researchapprenticeship/nomination/",
    loading_nominations: true,
    viewform: [],
    endpoint_viewable: "/api/petitionviewable/",
    visible: true,
    showModalStates: {},
  };

  componentDidMount() {
    this.getData();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.semester !== this.props.semester) {
      this.getData();
    };
  };

  addNewSubmission = (newData) => {
    this.setState((prevState) => ({
      viewform: [...prevState.viewform, newData]
    }));
  };

  updateExistingSubmission = (updatedData) => {
    this.setState((prevState) => {
      const updatedViewform = prevState.viewform.map((record) => {
        if (record.id === updatedData.id) {
          return { ...record, ...updatedData };
        }
        return record;
      });
      return { viewform: updatedViewform };
    });
  };

  deleteSubmission = (recordId) => {
    this.setState((prevState) => {
      const updatedViewform = prevState.viewform.filter((record) => record.id !== recordId);
      return { viewform: updatedViewform };
    });
  };

  getData = () => {
    // this.doGet(this.state.endpoint_viewable + "?semester=" + this.props.semesters.join(","), data => { this.setState({ visible: ((data[0] || {}).petitions_visible_mcs || false) }); });
    this.doGet(this.state.endpoint + "?semester=" + this.props.semesters.join(","), data => this.setState({ viewform: data, loading_survey: false }));
  };

  toggleModal = (recordId, isVisible) => {
    const showModalStates = { ...this.state.showModalStates };
    showModalStates[recordId] = isVisible;
    this.setState({ showModalStates });
  };

  handleModalEdit = e => {
    this.setState({
      showModal: false,
    });
  };

  handleModalCancel = (e) => {
    this.setState({
      showModal: false,
    });
  };

  handleDelete = (e) => {
    const recordId = e.id;
    const { endpoint } = this.state;
    this.doDelete(endpoint + recordId.toString() + "/", () => {
      this.deleteSubmission(recordId)
    },
      JSON.stringify());
  }

  render() {
    const { loading_survey, endpoint, viewform } = this.state;
    const loading = loading_survey;
    const { user } = this.props;

    const formClosed = !this.get_research_apprenticeship_nomination_status(this.props.semesters)
    const numberOfNoms = viewform.length

    const columns = [
      {
        title: "Nominee",
        key: "student",
        width: 100,
        render: (text, record, idx) => record.student?.firstname + " " + record.student.lastname
      },
      {
        title: "Reasoning",
        key: "reasoning",
        width: 200,
        render: (text, record, idx) => record.reasoning
      },
      {
        title: "Actions",
        key: "actions",
        width: 100,
        render: (text, record, idx) => (
          <span>
            <>
              <Button type="primary" disabled={formClosed} onClick={() => this.toggleModal(record.id, true)}>Edit</Button>
              <Modal
                title={"Edit Nomination for " + (record.student?.firstname + " " + record.student.lastname)}
                visible={this.state.showModalStates[record.id] || false}
                onEdit={this.handleModalEdit}
                onCancel={() => this.toggleModal(record.id, false)}
                footer={null}
              >
                <ApprenticeNominationEdit
                  {...this.props}
                  selectedStudent={record.student}
                  nomReasoning={record.reasoning}
                  recordId={record.id}
                  endpoint={endpoint}
                  getData={this.getData}
                  toggleModal={this.toggleModal}
                  updateExistingSubmission={this.updateExistingSubmission}
                >
                </ApprenticeNominationEdit>
              </Modal>
              <Divider type="vertical" />
              <Button type="danger" disabled={formClosed} onClick={() => this.handleDelete(record)}>Delete</Button>
            </>

          </span>
        )
      },
    ];

    return (
      <Content
        {...this.props}
        title={"MS Apprenticeship Nomination for " + this.props.semester}
        breadcrumbs={[{ link: "/employee", text: "Employee" }, { text: "Research Apprenticeship" }, { text: "Nomination" }]}
      >
        <p>
          Welcome to the Khoury Research Apprenticeship Nomination form. Please nominate
          up to five students that you feel would be good candidates for the apprenticeship,
          or those who have expressed an interest in becoming involved in research or continuing
          to Khoury's PhD program.
        </p>
        <p>
          Nominations may only be edited and deleted prior to the deadline.
        </p>
        <React.Fragment>
          <Divider orientation="left">
            Submitted MS Apprenticeship Nominations
          </Divider>
          <Table
            dataSource={viewform}
            columns={columns}
            loading={loading}
            rowKey={(record) => record.id}
          />
          {!this.get_research_apprenticeship_application_status(this.props.semesters) &&
            this.get_research_apprenticeship_nomination_status(this.props.semesters)
            ? (
              <React.Fragment>
                <Divider orientation="left">MS Research Apprenticeship Nomination</Divider>
                {(numberOfNoms < 5) ?
                  <>
                    <p>
                      Please nominate up to 5 students by filling out the form below.
                    </p>
                    <p>
                      <b>Note: </b>If you cannot find the student via in the dropdown, click <i>'Add Student'</i> to add them to the Admin Portal manually.
                    </p>
                  </>
                  :
                  <p style={{ color: 'red' }}>
                    You have nominated the maximum of 5 students for this semester.
                  </p>}
                <ApprenticeNominationForm
                  {...this.props}
                  getData={this.getData}
                  addNewSubmission={this.addNewSubmission}
                  endpoint={endpoint}
                  viewform={viewform}
                  loading_survey={loading}
                  numberOfNoms={numberOfNoms}
                />
              </React.Fragment>
            ) :
            <Divider orientation="center">
              {" "}
              Nomination Period is Closed
            </Divider>}
        </React.Fragment>
      </Content>
    );
  };
};

export { ApprenticeNomination };
