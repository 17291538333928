import React, { Component } from "react";
import { Layout, Card, List, Table, Form, Switch, Button, Modal, Input, Alert, InputNumber, Radio, Select, Menu, Dropdown, Icon, Tooltip, Checkbox, Divider, Popconfirm, message } from 'antd';

import AppComponent from '../AppComponent';
import LoginLayout from './LoginLayout';
import { AccessRequest, TempAccessRequestForm } from "../Petition/TempAccessRequest/TAAccessRequestForm";
const { Header, Content } = Layout;

class CannotImpersonate extends AppComponent {
  render() {
    const { form, handle_userrequirements, campus_list, user, has_nuid, stop_impersonate, has_campus } = this.props;
    return (
      <LoginLayout {...this.props}>
        <Card title="Unable to Impersonate this user" style={{ width: "40" }}>
          <Button type="primary" onClick={stop_impersonate}>Back to Safety</Button>
        </Card>
      </LoginLayout>
    );
  }
}

class NotKhouryEmployee extends AppComponent {

  render() {
    const { logged_out } = this.props;

    return (
      <LoginLayout {...this.props}>
        <Card title="We are unable to determine your affiliation to Khoury" style={{ width: "40" }}>
            <>
              <p>We are able to authenticate your login and determined your account type as being an Employee, but we are unable to determine if you are affiliated with Khoury.</p>
              <p>You will need to contact <a href="mailto:khoury-admin@ccs.neu.edu">the Khoury Admin Site administrators</a> and include your NUID, campus, position description, and why you are requesting access. We apologize for the inconvenience.</p>
              <p style={{ fontSize: "10px" }}><i>Note: All requests must come from a Northeastern affiliated e-mail, such as @northeastern.edu.</i></p>
            </>
          <Divider></Divider>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button size="small" onClick={() => logged_out(true)}><Icon type="logout" /> Log out</Button>
          </div>
        </Card>
      </LoginLayout>
    );
  }
}

class NoGroups extends AppComponent {
  render() {
    const { form, handle_userrequirements, campus_list, user } = this.props;

    return (
      <LoginLayout {...this.props}>
        <Card title="Unable to Identify Groups" style={{ width: "40" }}>
          <p>We are able to authenticate your login and determined your account type as being an Employee, but we are unable to determine if you are affiliated with Khoury.</p>
          <p>You will need to contact <a href="mailto:khoury-admin@ccs.neu.edu">the Khoury Admin Site administrators</a> and include your NUID, campus, position description, and why you are requesting access. We apologize for the inconvenience.</p>
        </Card>
      </LoginLayout>
    );
  }
}

class NoInstructor extends AppComponent {
  render() {
    const { form, handle_userrequirements, campus_list, user } = this.props;

    return (
      <LoginLayout {...this.props}>
        <Card title="Unable to Link Faculty Account" style={{ width: "40" }}>
          <p>We are able to authenticate your login as a faculty member, but we are unable to find a matching instructor record for you.  You will need to contact <a href="mailto:khoury-admin@ccs.neu.edu">the Khoury Admin Site administrators</a> and include your NUID, campus, and position description.  We apologize for the inconvenience.</p>
        </Card>
      </LoginLayout>
    );
  }
}

class NoPerson extends AppComponent {
  render() {
    const { form, handle_userrequirements, campus_list, user } = this.props;

    return (
      <LoginLayout {...this.props}>
        <Card title="Unable to Link to Banner or Workday" style={{ width: "40" }}>
          <p>We are able to authenticate your login, but we are unable to find a matching record for you in Banner or Workday.  You will need to contact <a href="mailto:khoury-admin@ccs.neu.edu">the Khoury Admin Site administrators</a> and include your NUID, campus, and brief information on your role at Khoury.  We apologize for the inconvenience.</p>
        </Card>
      </LoginLayout>
    );
  }
}

export { CannotImpersonate, NoGroups, NoInstructor, NoPerson, NotKhouryEmployee };
