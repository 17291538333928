import React, { Component } from "react";
import AppComponent from "../../../AppComponent";
import moment from "moment-timezone";
import { WorkflowSubmit } from "../../WorkflowSubmit";
import { WorkflowTable } from "../../WorkflowTable";
import Transcript from "../../../Transcript";

import { Icon, Popover, Table, Form, Input, Divider, Select, Button, InputNumber, message, Switch, Typography, Modal, Alert, Descriptions } from "antd";
const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { Text } = Typography;

//"Max Thomas Explore the World Scholarship"
// "Bradley E. Bailey Scholarship",
// "Darwin Scholarship",
// "Four Gavin Brothers Experiential Learning Fund",
// "Khoury 40th Anniversary Fund",
// "Motorola Solutions Foundation Scholarship",
// "Jane K. Wenzinger Scholarship"

const SCHOLARSHIPS = ["Bradley E. Bailey Scholarship", "Darwin Scholarship", "Four Gavin Brothers Experiential Learning Fund", "Khoury 40th Anniversary Fund", "Motorola Solutions Foundation Scholarship", "Jane K. Wenzinger Scholarship"];


const UGScholarshipApplicationForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    state = {
    }
    
    componentDidMount() {
      this.reset();
    }

    reset = () => {
      this.props.form.resetFields();
    }

    handleSubmit = () => {
      const form = this.props.form;
      const semester = this.props.semesters[0]

      form.validateFields((err, values) => {
        const scholarshipNames = []
        values.scholarships.forEach(name=> { scholarshipNames.push({ 'name': name }) })
        values['scholarships'] = scholarshipNames
        const data = {...values, "semester": semester}
        if (!err) {
          this.props.onSubmit( data , this.reset());
        } else {
          message.error(err);
        }
      });
    };

    handlePermission = () => {
      const { data } = this.props;
      return data?.some(dataEl => dataEl.steps.some(step => step.decision === 'Assigned')) || false;
    }
  

    render() {
      const { form, data, loading } = this.props;
  
      const { getFieldDecorator } = form;
      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 } }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }, colon: true };
   
      const submissionAssigned  = this.handlePermission();
      
      return (
        <>
       
          {(!loading && data && !submissionAssigned) ?
          <>
          <p>If you wish to amend a previously-submitted application, please simply submit a new application. The Khoury Undergrad team will simply use the most recent one that you submit.</p>
          <div style={{marginTop:"15px"}}>
          <Form>
          <FormItem {...formItemLayout} label="Scholarships" extra="Please select all the scholarships you would like to apply for.">
            {getFieldDecorator("scholarships", { rules: [{required: true, message: "Please select scholarship(s)"}] })
            (
              <Select style={{ width: 360 }} filterOption={ this.filter } mode="multiple">
                { SCHOLARSHIPS.map(el => <Option key={ el } value={ el }>{ el }</Option> ) }
              </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="GPA" extra="Please enter your current GPA.">
            {getFieldDecorator('gpa', { rules: [{ required: true, message: 'Please enter your GPA.' }]})
            (
              <InputNumber min={0} max={4} step={0.01} /> 
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Personal Statement" extra={ 
            <>
              <p>Please provide a personal statement (500 words) on how this financial support will impact your academic experience and long-term goals.</p>
              <ul>
                <li>How do you plan to utilize computer science to make a positive impact in your community or the world after you graduate?</li>
                <li>It is recommended to review the scholarship description and provide any relevant information for the particular scholarship.</li>
                <li><b>Note:</b> This field supports Markdown styling.</li>
              </ul>
            </>}>
            {getFieldDecorator("personal_statement", { rules: [{ required: true, message: "Please input a personal statement." }]})
            (
              <TextArea style={{ width: 500 }} rows={5} />
            )}
          </FormItem>
         <FormItem {...this.formItemLayout} label=" " colon={ false }><Button type="primary" onClick={ this.handleSubmit }>Submit</Button></FormItem>
        </Form>
      </div>
      </>
     : null }
     {!loading && data && submissionAssigned ?
     <>
      <Alert message="You already have an active application for this semester. Please cancel it before submitting again." type="warning"></Alert>
      </> :
      null
     }
    </>
      );
    }
  }
);        
 

class UGScholarshipApplication extends AppComponent {
  render() {
   const { record } = this.props;
   const item = record.record;
     
    return (
        <Descriptions bordered title={ "Scholarship application by " + record.created_by}>
          <Descriptions.Item label="Submitted" span={ 2 }>{ moment(record.created_at).format("MMM DD, YYYY HH:mm") }</Descriptions.Item>
          <Descriptions.Item label="Requestor" span={ 2 }>{[ record.created_by + " ", <a key="email" href={"mailto:" + item.email}><Icon type="mail" theme="twoTone" /></a> ]}</Descriptions.Item>
          <Descriptions.Item label="NUID" span={ 2 }>{ item.student.nuid }</Descriptions.Item>
          { this.permission("can", "staff") && 
          <Descriptions.Item label="" span={ 2 }>{<Transcript {...this.props} nuid={item.student.nuid} />}</Descriptions.Item> }
          <Descriptions.Item label="Campus" span={ 2 }>{ this.print_campus(item.student.campus) }</Descriptions.Item>
          <Descriptions.Item label="Scholarships" span={ 2 }>{ item.scholarships.map(scholarship=> scholarship.name).join(', ') }</Descriptions.Item>
          <Descriptions.Item label="GPA" span={ 2 }>{ item.gpa }</Descriptions.Item>
          <Descriptions.Item label="Personal Statement" span={ 4 }>{ item.personal_statement }</Descriptions.Item>
          <Descriptions.Item label="Semester" span={ 2 }>{ this.print_semester(item.semester) }</Descriptions.Item>
        </Descriptions>
      );
   }
}

class UGScholarshipApplicationTable extends WorkflowTable {
  get_columns = () => {
    return [
      // {
      //   title: "Requestor",
      //   key: "requestor",
      //   width: 150,
      //   render: (text, record, idx) => record.created_by
      // }, 
      {
        title: "Scholarships",
        key: "scholarships",
        width: 300,
        render: (text, record, idx) => record.record.scholarships.map(scholarship=> scholarship.name).join(', '),
      }
    ];
  }
}

class UGScholarshipApplicationOverview extends WorkflowSubmit {
  state = {
    endpoint: "/api/petition/ug/scholarshipapplication/",
    visible: true,
    endpoint_viewable: "/api/petitionviewable/",
  }

  getData = () => {
    this.setState({ loading: true }, () => this.doGet(this.state.endpoint+"?semester=" + this.props.semesters[0], data => { this.setState({ data: data, loading: false }) }));
    this.doGet(this.state.endpoint_viewable +"?semester=" + this.props.semesters.join(","), data => { this.setState({ visible: ((data[0]||{}).petitions_visible_sa||false) });});
  }

  get_name = () => {
    return "UG Scholarship Application";
  }

  get_workflowtype = () => {
    return "ugscholarshipapplication";
  }

  get_name_plural = () => {
    return "UG Scholarship Applications";
  }
  
  get_breadcrumbs = () => {
    return [{ link: "/student", text: "Students" }, { text: "UG" }, { text: "Scholarship Application" }];
  }
  
  get_form = (func) => {
    return <UGScholarshipApplicationForm {...this.props} onSubmit={ this.submit_form } data={this.state.data} loading={this.state.loading}/>;
  }
  
  get_record_view = (record) => {
    return <UGScholarshipApplication {...this.props} record={ record } />
  }
  
  submit_form = (data, func) => {
    this.doPost(this.state.endpoint, () => { this.getData(); func ? func() : null ; }, JSON.stringify(data));
  }
  
  get_overview_text = () => {
    return (
    <p>
      Welcome to the new scholarship application. 
      Description and eligibility requirements for Khoury Undergraduate Scholarships can be found at: <a href="https://www.khoury.northeastern.edu/information-for-overview/current-undergrad/scholarship-and-funding-opportunities/" target="_blank">https://www.khoury.northeastern.edu/information-for-overview/current-undergrad/scholarship-and-funding-opportunities/</a>
    </p>
    );
  }  
}

export { UGScholarshipApplicationOverview, UGScholarshipApplication, UGScholarshipApplicationTable };