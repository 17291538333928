import React, { Component } from "react";
import AppComponent from "../../../AppComponent";
import moment from "moment-timezone";
import { WorkflowSubmit } from "../../WorkflowSubmit";
import { WorkflowTable } from "../../WorkflowTable";
import Transcript from "../../../Transcript";

import { Icon, Radio, Upload, Form, Input, Divider, Button, message, Descriptions } from "antd";
import { oxford } from '../../../Utils';

const FormItem = Form.Item;
const { TextArea } = Input;

const PhDPaperRequirementForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    state = {
      uploadedList: [],
    }

    componentDidMount() {
      this.reset();
    }

    reset = () => {
      this.setState({ uploadedList: [] }); 
      this.props.form.resetFields();
    }
        
    handleSubmit = () => {
      const { uploadedList } = this.state;
      const form = this.props.form;

      form.validateFields((err, values) => {
        if (!err) {
          this.props.onSubmit({...values, ...{files: uploadedList}}, this.reset());
        } else {
          message.error(err);
        }
      });
    };

    getFile = (type) => {
      const { uploadedList } = this.state;
      return(
        <React.Fragment>
          {(uploadedList.filter(el => el[1]["type"] == type).length != 0) && (
            <div style={{marginTop:"10px"}}>
              <p>{ type } <Icon type="minus-circle-o" style={{ color: '#d9534f' }} onClick={ () => this.onRemove(type)} /></p>
            </div>
          )}
        </React.Fragment>
      )};

    setFile = (file, type) => {
      this.setState(state => ({
        uploadedList: [...state.uploadedList, [window.btoa(file), type]],
      }));
    };

    beforeUpload = (file, type) => {
      if (file.size / 1024 / 1024 > 10) {
        message.error('File is larger than 10MB');
        return;
      }
      const reader = new FileReader();
      const setFile = this.setFile;
      reader.onload = function(event) {
        setFile(event.target.result, type);
      };
      reader.readAsBinaryString(file);
  };

    onRemove = type => {
      this.setState(state => {
        const matches = (el) => el[1]["type"] == type;
        const index = state.uploadedList.findIndex(matches);
        const newuploadedList = state.uploadedList.slice();
        newuploadedList.splice(index, 1);
        return {
          uploadedList: newuploadedList,
        };
      });
    };

    disableUpload = (type) => {
      const { uploadedList } = this.state;
      return uploadedList.filter(el => el[1]["type"] == type).length != 0;
    }

    render() {
      const { form  } = this.props;
      const { getFieldDecorator } = form;
      const { current, advisor_statement, published_where, presented_at_conf, paper, file_end_point } = this.state;
      
      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 } }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }, colon: true };
     
      return (
        <>
          <p>If you wish to amend a previously-submitted paper requirement submission, please simply submit a new one. The Khoury PhD staff will use the most recent one that you submit.</p>
          <div style={{marginTop:"15px"}}>
          <Form>
              <FormItem {...formItemLayout} label="Advisor Statement" extra={ "A statement from your advisor clearly indicating your contributions to the paper and how these contributions provide evidence of research potential"}>
                  {getFieldDecorator("advisor_statement", { rules: [{ required: true, message: "Please input an advisor statement" }]})
                  (<TextArea style={{ width: 360 }} rows={4} />)}
              </FormItem>
              <FormItem {...formItemLayout} label="Advisor Venue Explanation" extra={ "Please include a brief statement from your advisor explaining the significance, competitiveness, or stature of the venue."}>
                  {getFieldDecorator("advisor_venue_explanation", { rules: [{ required: true, message: "Please input an advisor statement" }]})
                  (<TextArea style={{ width: 360 }} rows={2} />)}
              </FormItem>
              <FormItem {...formItemLayout} label="Published Citation" extra={"Please input the citation to the journal it was published by otherwise please leave this field blank"}>
                  {getFieldDecorator("published_where", { rules: [{ required: false }] })
                  (<TextArea style={{ width: 360 }} rows={4} />)}
              </FormItem>
              <FormItem {...formItemLayout} label="Presented?" extra="Was this paper presented at a conference?">
                {getFieldDecorator("presented_at_conf", { rules: [{ required: true, message: "Please select if the paper was presented" }]})
                  (<Radio.Group>
                    <Radio style={{display: 'block'}} value={true}>Yes</Radio>
                    <Radio style={{display: 'block'}} value={false}>No</Radio>
                  </Radio.Group>)}
              </FormItem>
              <Divider orientation="left">Add Documentation</Divider>
              <p>Please use the below space to upload the required documents, you cannot proceed without upload.</p>
              <strong>Note: You can only upload one document per field. It must be a PDF and no larger than 10 MB.</strong>
              <FormItem {...formItemLayout} label="Paper" extra={ "Please upload a copy of your Paper." } >
                {getFieldDecorator("paper", { rules: [{ required: true, message: 'Please upload a paper' }] })(
                  <Upload beforeUpload={(file) => this.beforeUpload(file, {"type": "Paper"})} accept=".pdf" showUploadList={false}>
                    <Button disabled={this.disableUpload("Paper")}><Icon type="upload" />Upload Paper</Button>
                    {this.getFile("Paper")}
                  </Upload>
                )}
              </FormItem>
              <FormItem {...this.formItemLayout} label=" " colon={ false }>
                <Button type="primary" onClick={ () => this.handleSubmit() }>Submit</Button>
              </FormItem>
            </Form>
          </div>
        </>
      );
    }
  }
);        
 

class PhDPaperRequirement extends AppComponent {

  getCurrentAdvisors = (current_code, record) => {
    const current_advisors = record.advisors.find(a => this.get_semester(a.start).code <= current_code && (a.end == null || this.get_semester(a.end).code >= current_code));
    return current_advisors ? oxford(current_advisors.advisors.map(e => this.print_instructor(e))) : <i>None</i>; 
  }

  render() {
   const { record } = this.props;
   const item = record.record;
     
    return (
        <Descriptions bordered title={ "PhD Paper Requirement submission by " + record.created_by} column={2}>
          <Descriptions.Item label="Submitted" >{ moment(record.created_at).format("MMM DD, YYYY HH:mm") }</Descriptions.Item>
          <Descriptions.Item label="Requestor" >{[ record.created_by + " ", <a key="email" href={"mailto:" + record.email}><Icon type="mail" theme="twoTone" /></a> ]}</Descriptions.Item>
          <Descriptions.Item label="NUID" >{ item.phdstudent.nuid }</Descriptions.Item>
          { this.permission("can", "staff") && 
          <Descriptions.Item label="" >{<Transcript {...this.props} nuid={item.phdstudent.nuid} />}</Descriptions.Item>
          }
          <Descriptions.Item label="Degree" >{ item.phdstudent.degree ? this.print_degree(item.phdstudent.degree): "Couldn't load" }</Descriptions.Item>
          <Descriptions.Item label="Campus" >{ this.print_campus(item.phdstudent.campus) }</Descriptions.Item>
          <Descriptions.Item label={<p>Advisor <small>(*at submission)</small></p>} >{ this.getCurrentAdvisors(this.find_semester(record.created_at), item.phdstudent) }</Descriptions.Item>
          <Descriptions.Item label="Matriculated" >{ item.phdstudent.matriculated ? this.print_semester(item.phdstudent.matriculated) : "Couldn't load"  }</Descriptions.Item>
          <Descriptions.Item label="Advisor Statement" span={ 2 }>{ item.advisor_statement }</Descriptions.Item>
          <Descriptions.Item label="Advisor Venue Explanation" span={ 2 }>{ item.advisor_venue_explanation ? item.advisor_venue_explanation : "" }</Descriptions.Item>
          <Descriptions.Item label="Published" >{ item.published_where ? item.published_where : "No" }</Descriptions.Item>
          <Descriptions.Item label="Presented" >{ JSON.stringify(item.presented_at_conf) }</Descriptions.Item>
          <Descriptions.Item label="Attached Files" span={ 2 }>{[record.files.map(el => <a onClick={ () => this.openPDF(el.attachment, el.file_type +".pdf") }><Icon type="file-pdf" theme="twoTone" twoToneColor="#eb2f96" /> {el.file_type +".pdf"}</a>) ]}</Descriptions.Item>
        </Descriptions>
      );
   }
}

class PhDPaperRequirementTable extends WorkflowTable {
  get_columns = () => {
    return [];
  }
}

class PhDPaperRequirementOverview extends WorkflowSubmit {
  state = {
    endpoint: "/api/petition/phd/paperrequirement/",
    visible: true,
    record_id: null,
  }

  getData = () => {
    this.setState({ loading: true }, () => this.doGet(this.state.endpoint, data => { this.setState({ data: data, loading: false }) }));
  }


  get_name = () => {
    return "PhD Paper Requirement";
  }

  get_workflowtype = () => {
    return "phdpaperrequirement";
  }

  get_name_plural = () => {
    return "PhD Paper Requirement";
  }
  
  get_breadcrumbs = () => {
    return [{ link: "/student", text: "Students" }, { text: "phd" }, { text: "Paper Requirement" }];
  }
  
  get_form = (func) => {
    return <PhDPaperRequirementForm {...this.props} onSubmit={ this.submit_form } />;
  }
  
  get_record_view = (record) => {
    return <PhDPaperRequirement {...this.props} record={ record } />
  }
  
  submit_form = (data, func) => {
    this.doPost(this.state.endpoint, () => { this.getData(); func ? func() : null; }, JSON.stringify(data));
  }
  
  get_overview_text = () => {
    return (
      <div>
        <p>Please ensure you have satisfied the requirements to your and your advisor’s satisfaction:</p>
        <ul>
          <li>A statement from your advisor clearly indicating your contributions to the paper and how these contributions provide evidence of research potential.</li>
          <li>A single PDF file with a size smaller than 10mbs.</li>
        </ul>
        <p><strong>Please allow two weeks for the PhD committee to review, discuss, vote, and communicate the outcome</strong></p>
      </div>
    );
  }  
}

export { PhDPaperRequirementOverview, PhDPaperRequirement, PhDPaperRequirementTable };