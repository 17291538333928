import React, { Component } from "react";
import { Link } from "react-router-dom";
import key from "weak-key";
import NumberFormat from 'react-number-format';
import AppComponent from './AppComponent';
import CoordinatorTable from './CoordinatorTable';
import Content from './Content';
import SectionTable from './SectionTable';
import ClassSizeTable from './ClassSizeTable';
import { add_brs } from './Utils';
import CustomTabs from './CustomTabs';

import { PageHeader, Table, Card, Collapse, List, Form, Switch, Layout, Tooltip, Icon, Menu, Dropdown, Divider, Breadcrumb, Tabs, Spin, Alert, message, Typography } from 'antd';
const TabPane = Tabs.TabPane;

const { Text, Title } = Typography;

class ScheduleCard extends AppComponent {
  state = {
    endpoint: "/api/schedule/",
    data: [],
    data_staff: [],
    loading: true,
    loading_staff: true,

    endpoint_coordinator: "/api/schedule/coordinator/",
    coordinator: [],
    coordinated: [],
    loading_coordinator: true,
    loading_coordinated: true,


    endpoint_sectionGroup: "/api/schedule/group/",
    sectionGroup: [],
    loading_sectionGroup: true,
  }


  componentDidMount() {
    this.getData()
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
      this.setState({ data: [], coordinator: [], coordinated: [], sectionGroup: [], loading: true, loading_coordinator: true, loading_coordinated: true, loading_sectionGroup: true });
      this.getData();
    }
  }

  isPartOfSectionGroup = (sections) => {
    return sections.some(el => el.instructors.includes(this.props.user.instructor))
  }

  getData = () => {
    if (this.props.user.instructor) {
      this.doGet(this.state.endpoint + "?deleted=False&semester=" + this.props.semesters.join(",") + "&instructors=" + this.props.user.person.id,
        data => this.setState({ data: data, loading: false }));
      this.doGet(this.state.endpoint + "?deleted=False&semester=" + this.props.semesters.join(",") + "&instructor_staff=" + this.props.user.person.id,
        data => this.setState({ data_staff: data, loading_staff: false }));
      this.doGet(this.state.endpoint_coordinator + "?semester=" + this.props.semesters.join(",") + "&instructor=" + this.props.user.instructor,
        data => this.setState({ coordinator: data, loading_coordinator: false, loading_coordinated: data?.length > 0 }, () =>
          data?.length > 0 ? this.doGet(this.state.endpoint + "?deleted=False&semester=" + this.props.semesters.join(",") + "&campus=" + this.props.campus + ",118&course=" + data.map(c => [c.course].concat(this.get_course(c.course).lab ? [this.get_course(c.course).lab] : [])).flat().join(","), data => this.setState({ coordinated: data, loading_coordinated: false })) : null));
      this.doGet(this.state.endpoint_sectionGroup + "?semester=" + this.props.semesters.join(","), data => this.setState({ sectionGroup: data.filter(el => this.isPartOfSectionGroup(el.sections)), loading_sectionGroup: false }));
    } else {
      this.setState({ loading: false, loading_coordinator: false, loading_coordinated: false, loading_staff: false });
    }
  }

  renderSections = (data) => {
    return (
      add_brs(data.map(el => (<span key={el.id}><Link key={el.id} to={this.getLink("/teaching/schedules/section/" + el.id)}>{this.print_section(el)}</Link> ({this.print_campus(el.campus)}{this.props.semesters.length > 1 ? ", " + this.print_semester(el.semester) : null}{!el.instructors.includes(this.props.user.instructor) ? ", coordinated" : null}{el.accelerated ? ", accelerated" : null}{el.honors ? ", honors" : null})</span>)))
    );
  }


  renderSectionGroups = (sectionGroup) => {
    return (
      add_brs(sectionGroup.map(el => <span key={el.id}><Link key={el.id} to={this.getLink("/teaching/schedules/sectiongroup/" + el.id)}>{this.print_course(el.sections[0].course) + " (" + el.name + ")"}</Link> ({"Section Group"})</span>))
    );
  }

  render() {
    const { loading, loading_coordinated, loading_coordinator, data, data_staff, loading_staff, coordinated, sectionGroup, loading_sectionGroup } = this.state;

    const mycoordinated = coordinated.filter(s => !data.find(t => t.id == s.id));

    return (<Card size="small" title={"Your sections in " + this.props.semester}>{loading || loading_coordinated || loading_coordinator || loading_staff || loading_sectionGroup ? (<Spin tip="Loading sections" />) : data.length || data_staff.length || mycoordinated.length || sectionGroup.length ?

      <>
        <>{this.renderSections(data.concat(data_staff).concat(mycoordinated))}</>
        <br />
        <>{this.renderSectionGroups(sectionGroup)}</>
      </> : <i>No sections found</i>}</Card>);
  }
}

class Schedule extends AppComponent {
  state = {
    endpoint_section: "/api/schedule/section/",
    data: [],
    loading: true,

    endpoint: "/api/schedule/",
    endpoint_planning: "/api/planning/",
    sections_planning: false,

    endpoint_coordinator: "/api/schedule/coordinator/",
    coordinators: [],
    loading_coordinators: true,

    endpoint_warnings: "/api/schedule/warnings/",
    warnings: [],
    loading_warnings: true,

    all_campuses: false,
  };

  componentDidMount() {
    this.setState({ all_campuses: this.props.user.groups.map(el => el.name).includes("admin") }, () => { this.getData() });
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
      this.setState({ loading: true, warnings: [], coordinators: [], loading_coordinators: true, loading_warnings: true, data: [] });
      this.getData();
    }
  }

  getData = () => {
    this.doGet(this.state.endpoint + "?semester=" + this.props.semesters.join(",") + (!this.state.all_campuses ? "&campus=" + this.props.campus : ""), this.handleSections);
    if (this.permission("can", "staff") || this.permission("can", "faculty")) {
      this.doGet(this.state.endpoint_coordinator + "?semester=" + this.props.semesters.join(","),
        data => this.setState({ coordinators: data, loading_coordinators: false }));
    }
    if (this.permission("can", "staff")) {
      this.doGet(this.state.endpoint_warnings + "?semester=" + this.props.semesters.join(",") + "&campus=" + this.props.campus,
        data => this.setState({ warnings: data, loading_warnings: false }));
    }
  }

  handleSections = (data) => {
    if (data?.length > 0) {
      this.setState({ data: data, loading: false, sections_planning: false });
    } else {
      if (this.permission("can", "staff") || this.permission("can", "faculty")) {
        this.doGet(this.state.endpoint_planning + "?semester=" + this.props.semesters.join(",") + "&campus=" + this.props.campus + ",118", data => this.setState({ data: this.deduplicateSections(data), loading: false, sections_planning: true }));
      } else {
        this.setState({ data: [], loading: false, sections_planning: false });
      }
    }
  }

  getActions = (text, record, idx) => {
    const menu = (
      <Menu>
        <Menu.Item>
          <a onClick={() => this.handleUpdate(record.id, !record.ugrad_stats, record.loadcount)}>{record.ugrad_stats ? "Remove stats" : "Add stats"}</a>
        </Menu.Item>
        <Menu.Item>
          <a onClick={() => this.handleUpdate(record.id, record.ugrad_stats, 0)}>{"Set load count 0"}</a>
        </Menu.Item>
        <Menu.Item>
          <a onClick={() => this.handleUpdate(record.id, record.ugrad_stats, 0.25)}>{"Set load count 0.25"}</a>
        </Menu.Item>
        <Menu.Item>
          <a onClick={() => this.handleUpdate(record.id, record.ugrad_stats, 0.333333333)}>{"Set load count 0.33"}</a>
        </Menu.Item>
        <Menu.Item>
          <a onClick={() => this.handleUpdate(record.id, record.ugrad_stats, 0.5)}>{"Set load count 0.5"}</a>
        </Menu.Item>
        <Menu.Item>
          <a onClick={() => this.handleUpdate(record.id, record.ugrad_stats, 1)}>{"Set load count 1"}</a>
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menu}>
        <a className="ant-dropdown-link">
          <div style={{ width: "75px" }}>Actions <Icon type="down" /></div>
        </a>
      </Dropdown>
    );
  }

  handleUpdate = (id, ugrad_stats, loadcount) => {
    const data = { "ugrad_stats": ugrad_stats, "loadcount": loadcount };

    this.doPatch(this.state.endpoint_section + id + "/edit/", () => { message.success("Edited section."); this.getData(); }, JSON.stringify(data));
  }

  allCampuses = (checked) => {
    this.setState({ loading: true, all_campuses: checked }, () => { this.getData() });
  };

  renderSemester = (semester) => {
    const { loading, data, warnings, all_campuses, sections_planning } = this.state;

    return (
      <React.Fragment key={semester}>
        <Divider orientation="left">{this.print_semester(semester)} Section Schedule</Divider>
        {loading ? <Spin tip="Loading sections" /> : data?.length > 0 ? (
          <CustomTabs {...this.props}>
            {this.subject_list().map((el, idx) => {
              const sections = data.filter(s => s.semester == semester && this.get_course(s.course).subject == el.id);

              return sections?.length > 0 ? (
                <TabPane tab={this.print_subject(el.id) + " (" + this.print_subject_code(el.id) + ")"} key={idx}>
                  {all_campuses
                    ? <SectionTable {...this.props} data={sections} loading={loading} getActions={this.getActions} show_fields={["campus"]} hide_fields={["semester"]} warnings={warnings} sections_planning={sections_planning} />
                    : <SectionTable {...this.props} data={sections} loading={loading} getActions={this.getActions} hide_fields={["semester"]} warnings={warnings} sections_planning={sections_planning}/>
                  }
                </TabPane>) : null;
            })}
          </CustomTabs>
        ) : <div><Alert message="No sections found" description={"We did not find any sections for the selected semester.  This may be because the semester has not been planned yet."} type="warning" showIcon /></div>}
      </React.Fragment>
    );
  }

  render() {
    const { loading, data, loading_coordinators, coordinators, warnings, sections_planning, all_campuses, loading_all_campuses } = this.state;
    const { semester } = this.props;

    const { Panel } = Collapse;

    var chars = [];

    if (this.permission("can", "staff") || this.permission("can", "faculty")) {
      chars = chars.concat([{ title: "Coordinators", content: coordinators.length ? <CoordinatorTable {...this.props} loading={loading_coordinators} coordinators={coordinators} /> : "None" }]);
    }

    if (this.permission("can", "staff")) {
      chars = chars.concat(this.props.semesters.map(sem => { return { title: "Class Size for " + this.print_semester(sem), content: <ClassSizeTable {...this.props} loading={loading} sections={data.filter(el => !el.deleted)} semester_specific={sem} /> }; }))
    }

    const ids = data.map(el => el.id);
    const warnings_shown = {};

    const warnings_section = warnings.filter(el => ids.includes(el.id));
    const warnings_global = warnings.filter(el => !ids.includes(el.id));

    const warning = loading || loading_coordinators || data?.length > 0 ? sections_planning ? <Alert message="Planning schedule." description={"Please note that the " + semester + "  schedule is a draft schedule; changes may be made before it is finalized."} type="info" showIcon /> : null : (<Alert message="No sections found." description={"We did not find any sections listed during the " + semester + " semester."} type="warning" showIcon />);
    const defaultActiveKey = this.props.campus == 116 | this.props.campus == 118 ? "1" : "2"

    const toggleChecked = all_campuses || this.props.user.groups.map(el => el.name).includes("admin")

    return (
      <Content {...this.props} title={this.props.semester + " Teaching Schedule"} breadcrumbs={[{ link: "/teaching", text: "Teaching" }, { text: "Schedules" }, { text: this.props.semester }]}>
        {(this.permission("can", "staff") || this.permission("can", "faculty")) ? (
          <>
            <p style={{ float: 'right' }} >
              <Text strong> Show all campuses &nbsp;</Text>
              <Switch checked={all_campuses} loading={loading} onChange={this.allCampuses} />
            </p>
            {" "}
            <div style={{ clear: 'both' }}></div> </>) : null}
        {warning}
        {chars?.length > 0 ? (
          <React.Fragment>
            <Divider orientation="left">Characteristics</Divider>
            <List
              grid={this.grid}
              dataSource={chars.filter(el => el != null)}
              renderItem={item => (<List.Item><Card size="small" title={item.title}>{item.content}</Card></List.Item>)}
            />
          </React.Fragment>
        ) : null}
        {(warnings === undefined) || !(this.permission("can", "staff") || this.permission("can", "faculty")) ? null : (
          <React.Fragment>
            <Divider orientation="left">Warnings</Divider>
            <Collapse accordion bordered={false} defaultActiveKey="1">
              <Panel header="Show warnings" key={defaultActiveKey} style={{ fontStyle: 'italic' }}>
                {warnings_section.length + warnings_global?.length > 0 ? warnings_global.map((el, idx) => (<React.Fragment key={idx + " " + el.warning}><Alert message={el.warning} type="warning" showIcon /><br /></React.Fragment>)).concat(warnings_section.map(el => {
                  if (el.warning_type == "global") {
                    if (warnings_shown[el.warning]) {
                      return null;
                    } else {
                      warnings_shown[el.warning] = 1;
                    }
                  }
                  const foundSection = data.find(e => e.id == el.id);
                  return (<React.Fragment key={foundSection.id + " " + el.warning}><Alert message={(el.warning_type == "section" ? this.print_course(foundSection.course) + " at " + this.print_meetingtime(foundSection.meetingtime) + ": " : "") + el.warning} type="warning" showIcon /><br /></React.Fragment>);
                }))
                  : <Alert message="No errors detected" type="success" showIcon />}
              </Panel>
            </Collapse>
            {/* { warnings_section.length + warnings_global?.length > 0 ? 
                warnings_global.map((el, idx) => ( <React.Fragment key={ idx + " " + el.warning }><Alert message={ el.warning } type="warning" showIcon /><br /></React.Fragment> ) ).concat(warnings_section.map(el => { 
                if (el.warning_type == "global") {
                  if (warnings_shown[el.warning]) {
                    return null;
                  } else {
                    warnings_shown[el.warning] = 1;
                  }
                }
                const rec = data.find(e => e.id == el.id); 
                return ( <React.Fragment key={ rec.id + " " + el.warning }><Alert  message={ (el.warning_type == "section" ? this.print_course(rec.course) + " section " + rec.number + " CRN " + rec.crn + ": " : "") + el.warning } type="warning" showIcon /><br /></React.Fragment> ); 
              } ))
               : <Alert message="No errors detected" type="success" showIcon /> } */}
          </React.Fragment>)}
        {this.props.semesters.map(this.renderSemester)}
      </Content>
    );
  }
}



export default Schedule;
export { ScheduleCard };