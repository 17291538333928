import React, { Component } from "react";
import moment from "moment";
import key from "weak-key";
import NumberFormat from 'react-number-format';
import AppComponent from './AppComponent';
import Content from './Content';
import SectionTable from './SectionTable';
import CustomTabs from "./CustomTabs";
import { CSVLink } from "react-csv";
import { oxford, get_check_nocheck, add_brs, format_nuid } from './Utils';

import { PageHeader, Alert, Table, Form, Switch, Layout, Tooltip, Icon, Menu, Dropdown, Spin, Calendar, Col, Statistic, Button, Row, List, Card, Divider, Badge, Modal, Breadcrumb, Checkbox, message, Input, Tabs } from 'antd';
const TabPane = Tabs.TabPane;

class DescriptionTable extends AppComponent {
  render() {
    const { section, loading } = this.props;

    return loading ? "" : (
      <React.Fragment>
        <b>Course</b>: {this.link_course(section.course)} {section.title ? <i>{section.title}</i> : this.print_course_title(section.course)}<br />
        <b>Instructor</b>: {oxford(section.instructors.map(el => this.link_instructor(el)))}<br />
        <b>CRN</b>: {section.crn}<br />
        <b>Meeting time</b>: {this.print_meetingtime(section.meetingtime)}<br />
        <b>Room</b>: {this.link_full_room(section.room)}<br />
        <b>Enrolled</b>: {section.enrollment + "/" + section.capacity}<br />
        <b>Waitlist</b>: {section.waitlist_capacity ? section.waitlist_enrollment + "/" + section.waitlist_capacity : "N/A"}<br />
      </React.Fragment>
    );
  }
}

class StudentDetails extends AppComponent {

  truncateDisplayedEmail = (email) => {
    return email && email.length > 29 ? email.substring(0, 26) + "..." : email;
  }

  render() {
    const { section, loading, filtered_taapplications, all } = this.props;
    const enrolled = section ? section.map(el => el.students.map( st => ({ ...st, crn: el.crn })).filter(s => s.status == "active")).reduce((accum, ac) => accum.concat(ac), []) : 
    [];
    const dropped = section ? section.map(el => el.students.filter(s => (s.status == "dropped") || (s.status == "withdrawn"))).reduce((accum, ac) => accum.concat(ac), []) : [];
    return loading ? "" : (
      <React.Fragment>
        {section && section.ias && section.ias?.length > 0 ? (
          <React.Fragment>
            <Divider orientation="left">Assigned IAs ({section.ias.length})</Divider>
            <List
              grid={this.grid_photos}
              dataSource={section.ias}
              renderItem={item => (<List.Item><Card size="small" title={this.print_full_student(item) + " (" + format_nuid(item.nuid) + ")"}><div className="student-photo"><img src={item.photo_url} /></div>{item.email ? <div><a href={"mailto:" + item.email}><Icon type="mail" /> {this.truncateDisplayedEmail(item.email)}</a></div> : null}</Card></List.Item>)}
            />
          </React.Fragment>
        ) : null}
        <Divider orientation="left">Assigned TAs ({filtered_taapplications.length})</Divider>
        <p>Below is the list of TAs who are currently assigned to this course (though not necessarily to your section).  If you need to, you can send an <a href={"mailto:" + filtered_taapplications.map(s => s.ta.email).join(",")}>email all of the TAs</a>. </p>
        <List
          grid={this.grid_photos}
          dataSource={filtered_taapplications}
          renderItem={item => (<List.Item><Card size="small" title={this.print_full_student(item.ta) + " (" + format_nuid(item.ta.nuid) + ")"}><div className="student-photo"><img src={item.ta.photo_url} /></div>{item.ta.email ? <div><a href={"mailto:" + item.ta.email}><Icon type="mail" /> {this.truncateDisplayedEmail(item.ta.email)}</a></div> : null}</Card></List.Item>)}
        />
        <Divider orientation="left">Enrolled Students ({enrolled.length})</Divider>
        <p>Below is the list of students who are currently enrolled in this section.  {section && section.length != 0 ? <>If you need to, you can send an <a href={"mailto:?bcc=" + enrolled.filter(s => s.status == "active").map(s => s.email).join(",")}>email</a> or <CSVLink filename={(all ? all : this.print_section(section[0])) + "-" + this.print_semester(section[0].semester) + ".csv"} data={enrolled.filter(s => s.status == "active").map(({ rnum, id, photo_url, isremote, nuflex, phdstudent, ...item }) => item)}>download</CSVLink> data on all enrolled students.</> : null}</p>
        <List
          grid={this.grid_photos}
          dataSource={enrolled}
          renderItem={item => (<List.Item><Card size="small" title={this.print_full_student(item) + " (" + item.nuid + ")"}><div className="student-photo"><img src={item.photo_url} /></div>{item.email ? <div><a href={"mailto:" + item.email}><Icon type="mail" /> {this.truncateDisplayedEmail(item.email)}</a></div> : null}</Card></List.Item>)}
        />
        <Divider orientation="left">Withdrawn/Dropped Students ({dropped.length})</Divider>
        <p>Below is the list of students who were enrolled, but who have dropped this section.  {section ? <>If you need to, you can send an <a href={"mailto:?bcc=" + dropped.map(s => s.email).join(",")}>email all dropped students</a>.</> : null}</p>
        <List
          grid={this.grid_photos}
          dataSource={dropped}
          renderItem={item => (<List.Item><Card size="small" title={this.print_full_student(item) + " (" + item.nuid + ")"}><div className="student-photo"><img src={item.photo_url} /></div>{item.email ? <div><a href={"mailto:" + item.email}><Icon type="mail" /> {this.truncateDisplayedEmail(item.email)}</a></div> : null}</Card></List.Item>)}
        />
      </React.Fragment>
    );
  }
}

class SectionGroup extends AppComponent {
  state = {
    endpoint: "/api/schedule/section/",
    sectionList: [],
    loading: true,

    endpoint_group: "/api/schedule/group/",
    group: [],
    loading_group: true,

    endpoint_taapplication: "/api/ta/application/",
    taapplications: [],
    loading_taapplications: true,

  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.doGet(this.state.endpoint_group + "?semester=" + this.props.semesters.join(","), data => this.setState({ group: data.find(el => el.id == this.props.match.params.group_id), loading_group: false }, () => this.getOtherData()));
  }

  getOtherData = () => {
    const { loading_group, group, endpoint, endpoint_taapplication, sectionList } = this.state;

    // List of unique courses from all the sections in the section group
    const uniqueCourseNumbers = [...new Set(group.sections.map(section => section.course))];
    const courseParam = uniqueCourseNumbers.join(',');

    if (!loading_group) {
      group.sections.map(el =>
        (this.doGet(endpoint + el.id + "/", data => this.setState(prevState => ({ sectionList: [...prevState.sectionList, data] })))));
      this.setState({ loading: false });
      this.doGet(
        `${endpoint_taapplication}?semester=${group.sections[0].semester}&course=${courseParam}&state=Assigned,Accepted,Hire-Submitted,I-9-incomplete,EIB,Processed,Hired`,
        data => data 
          ? this.setState({ taapplications: data, loading_taapplications: false }) 
          : this.setState({ loading_taapplications: false })
      );    }
  }

  render() {
    const { sectionList, loading, group, loading_group, taapplications, loading_taapplications } = this.state;
    const all_loading = loading || loading_taapplications || loading_group;
    const title = "Section Group: " + (loading_group ? this.props.match.params.group_id : (group.name + " (" + this.print_course(group.sections[0].course) + ")"));
    const filtered_taapplications = taapplications.filter(el => el.group == group.id);

    let sortedSectionList = sectionList ? sectionList.sort((a, b) => (a.number > b.number) ? 1 : -1) : [];

    const chars = sortedSectionList.map(section => (
      { section: section, title: "Section " + section.number, content: (<DescriptionTable {...this.props} loading={all_loading} section={section} />) }));

    return all_loading ? (<i>Loading...</i>) : (
      <Content {...this.props} title={title} breadcrumbs={[{ link: "/teaching", text: "Teaching" }, { link: "/teaching/schedules", text: "Schedules" }, { link: "/teaching/schedules", text: this.props.semester }, { text: all_loading ? "Group " + this.props.match.params.group_id : group.name }]}>
        <Divider orientation="left">Characteristics</Divider>
        <List
          grid={this.grid}
          dataSource={chars}
          loading={!sortedSectionList}
          renderItem={item => (<List.Item><Card size="small" title={item.title}>{item.content}</Card></List.Item>)}
        />
        <CustomTabs {...this.props} default_Active_Key="all">
          <TabPane tab="All" key="all">
            <StudentDetails {...this.props} all={group.name} section={sortedSectionList} loading={all_loading} filtered_taapplications={filtered_taapplications} />
          </TabPane>
          {sortedSectionList.length > 1 && sortedSectionList.map(section => (
            <TabPane tab={"Section " + section.number + " (CRN: " + section.crn + ")"} key={section.crn}>
              <StudentDetails {...this.props} section={[section]} loading={all_loading} filtered_taapplications={filtered_taapplications} />
            </TabPane>
          ))}
        </CustomTabs>
      </Content>
    );
  }
}

export default SectionGroup;