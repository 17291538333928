import React from "react";
import AppComponent from "../../../AppComponent";
import moment from "moment-timezone";
import { WorkflowSubmit } from "../../WorkflowSubmit";
import { WorkflowTable } from "../../WorkflowTable";
import Transcript from "../../../Transcript";

import { Icon, Form, Input, Button, message, Descriptions } from "antd";
import { oxford } from '../../../Utils';

const FormItem = Form.Item;

const PHIQualExamForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {

    componentDidMount() {
      this.reset();
    }

    reset = () => {
      this.props.form.resetFields();
    }
        
    handleSubmit = () => {
      const form = this.props.form;

      form.validateFields((err, values) => {
        if (err) return;
        
        this.props.onSubmit(values, this.reset());
        
      });
    };


    render() {
      const { form  } = this.props;
      const { getFieldDecorator } = form;
      
      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 } }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }, colon: true };
     
      return (
        <>
        <div style={{marginTop:"15px"}}>
          <Form>
            <FormItem 
              {...formItemLayout} 
              label="Link to Web Page" 
              extra="Please include a link (URL with https://www) to your web page."
            >
              {getFieldDecorator("webpage_link", { 
                rules: [
                  { required: true, message: "This field is required." },
                  { type: "url", message: 'Must be a URL beginning with https://www' } // Add URL validation
                ]
              })
              (<Input style={{ width: 360 }} />)}
            </FormItem>
            
            <FormItem {...this.formItemLayout} label=" " colon={ false }>
              <Button type="primary" onClick={ () => this.handleSubmit() }>Submit</Button>
            </FormItem>
          </Form>
        </div>
        </>
      );
    }
  }
);        
 

class PHIQualExam extends AppComponent {

  getCurrentAdvisors = (current_code, record) => {
    const current_advisors = record.advisors.find(a => this.get_semester(a.start).code <= current_code && (a.end == null || this.get_semester(a.end).code >= current_code));
    return current_advisors ? oxford(current_advisors.advisors.map(e => this.print_instructor(e))) : <i>None</i>; 
  }

  render() {
   const { record } = this.props;
   const item = record.record;
     
    return (
        <Descriptions bordered title={ "PHI Qualifying Exam Requirements submission by " + record.created_by}>
          <Descriptions.Item label="Submitted" span={ 2 }>{ moment(record.created_at).format("MMM DD, YYYY HH:mm") }</Descriptions.Item>
          <Descriptions.Item label="Requestor" span={ 2 }>{[ record.created_by + " ", <a key="email" href={"mailto:" + record.email}><Icon type="mail" theme="twoTone" /></a> ]}</Descriptions.Item>
          <Descriptions.Item label="NUID" span={ 2 }>{ item.phdstudent.nuid }</Descriptions.Item>
          { this.permission("can", "staff") && 
          <Descriptions.Item label="" span={ 2 }>{<Transcript {...this.props} nuid={item.phdstudent.nuid} />}</Descriptions.Item> }
          <Descriptions.Item label="Degree" span={ 2 }>{ item.phdstudent.degree ? this.print_degree(item.phdstudent.degree): "Couldn't load" }</Descriptions.Item>
          <Descriptions.Item label="Campus" span={ 2 }>{ this.print_campus(item.phdstudent.campus) }</Descriptions.Item>
          <Descriptions.Item label={<p>Advisor <small>(*at submission)</small></p>} span={ 2 }>{ this.getCurrentAdvisors(this.find_semester(record.created_at), item.phdstudent) }</Descriptions.Item>
          <Descriptions.Item label="Matriculated" span={ 2 }>{ item.phdstudent.matriculated ? this.print_semester(item.phdstudent.matriculated) : "Couldn't load"  }</Descriptions.Item>
          <Descriptions.Item label="Link to Web Page" span={ 2 }>{<a key="website" href={item.webpage_link} target="_blank" rel="noopener noreferrer">{item.webpage_link}</a> }</Descriptions.Item>
        </Descriptions>
      );
   }
}

class PHIQualExamTable extends WorkflowTable {
  get_columns = () => {
    return [];
  }
}

class PHIQualExamOverview extends WorkflowSubmit {
  state = {
    endpoint: "/api/petition/phd/phiqualexam/",
    visible: true,
    record_id: null,
  }

  getData = () => {
    this.setState({ loading: true }, () => this.doGet(this.state.endpoint, data => { this.setState({ data: data, loading: false }) }));
  }


  get_name = () => {
    return "PHI Qualifying Exam Requirement";
  }

  get_workflowtype = () => {
    return "phiqualexamrequirement";
  }

  get_name_plural = () => {
    return "PHI Qualifying Exam Requirements";
  }
  
  get_breadcrumbs = () => {
    return [{ link: "/student", text: "Students" }, { text: "PhD" }, { text: "PHI Qualifying Exam" }];
  }
  
  get_form = (func) => {
    return <PHIQualExamForm {...this.props} onSubmit={ this.submit_form } />;
  }
  
  get_record_view = (record) => {
    return <PHIQualExam {...this.props} record={ record } />
  }
  
  submit_form = (data, func) => {
    this.doPost(this.state.endpoint, () => { this.getData(); func ? func() : null; }, JSON.stringify(data));
  }
  
  get_overview_text = () => {
    return (
      <div>
         <div>
         <h4>This form is for students pursuing a PhD in Personal Health Infomatics.
         To formally complete the PHI qualifier exam, students should create a web page with the following information:</h4>
          <ul>
            <li><strong>Student’s name</strong></li>
            <li><strong>Research core info</strong></li>
              <ul>
                <li>Paper title, author list, and abstract</li>
                <li>Link to a PDF of the paper</li>
                <li>The name of the conference/journal the paper has or will be submitted to</li>
                <li>A short justification of why the conference/journal is sufficiently competitive to meet the paper requirement, which may include acceptance rates or impact factors</li>
                <li>A statement from the PHI examiner indicating what roles the student played in the work presented, and what role the examiner and other co-authors played</li>
                <li>Status of the paper (e.g., submitted and date, accepted and date, in revision, date to be submitted, etc.)</li>
                <li>Date of the student’s presentation on the work</li>
              </ul>
          </ul>

          <ul>
            <li><strong>Health component</strong></li>
            <ul>
              <li>A paragraph description of the exam topic and why it was selected</li>
              <li>The name and title of the faculty examiner, and why that individual was selected</li>
              <li>A description of how the exam was structured, and why (e.g., literature review)</li>
              <li>A link to the work product produced</li>
              <li>The date when the exam was completed</li>
            </ul>
          </ul>
          <ul>
            <li><strong>Technical component</strong></li>
            <ul>
              <li>Same as for the Health Component (see above)</li>
            </ul>
          </ul>
        </div>
        <i>These requirements can be referenced in the PHI handbook.
        Please allow two weeks for the committee to review, discuss, vote, and communicate the outcome.</i>
      </div>
    );
  }  
}

export { PHIQualExamOverview, PHIQualExam, PHIQualExamTable };