import React, { Component } from "react";
import { BrowserRouter as Router, Switch as RouterSwitch, Route, Link, Redirect, withRouter } from "react-router-dom";
import moment from "moment-timezone";
import QRCode from 'qrcode.react';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import queryString from 'query-string';
import Preference from '../../Preference';
import AppComponent from '../../AppComponent';
import Content from '../../Content';
import FileUpload from "../../FileUpload";
import CustomTabs from '../../CustomTabs';
import { CommitteeCard } from '../../Committee';
import { PositionCard } from '../../Position';
import SectionTable from '../../SectionTable';
import { PhDStudentTeachingLoadList, PhDStudentAdvisingPointsTable, PhDStudentFundingPointsTable, PhDStudentData } from '../../PhD/PhDStaff';
import { add_dividers, get_check, get_nocheck, format_nuid, get_check_nocheck, oxford, get_merit_tag, add_dividers_horiz, Spin, get_merit_score, manageStateItems} from "../../Utils";

import { PageHeader, Layout, Dropdown, Menu, Breadcrumb, Icon, Input, Select, DatePicker, Table, Tabs, Tag, Tooltip, Radio, Button, Divider, Form, Switch, message, Modal, List, Card, InputNumber, Alert, Upload, Steps } from 'antd';
const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const TabPane = Tabs.TabPane;
const { SubMenu } = Menu;
const { Step } = Steps;
const { Sider, Footer } = Layout;
const { TextArea } = Input;
const { Option } = Select;

const DEFAULT_WEIGHTS = { weight_research: 40, weight_teaching: 40, weight_service: 20 };

const MAX_PUBLICATIONS = 5;
const MAX_ACTIVITIES = 5;
const MAX_EXEMPLARY = 3;

const MeritForm = Form.create({ name: 'form_not_in_modal' })(
    class extends AppComponent {
    state = {
      current: 0,      
    }

    formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

    scores = ["Exemplary", "Satisfactory", "Unsatisfactory", "Not Applicable"];
  
    next() {
      this.save(() => { window.scrollTo(0, 0); this.setState({ current: this.state.current + 1 }); } );
    }
  
    prev() {
      this.save(() => { window.scrollTo(0, 0); this.setState({ current: this.state.current - 1 }); } );
    }

    save = (callback) => {
      const { publications, exemplary_research, exemplary_teaching, activities, exemplary_service, current } = this.state;
      const { handleCreateUpdate, disabled } = this.props;
      const editable = !this.committee_present() && !this.dean_present() && !disabled;
      
      if (editable) { 
        handleCreateUpdate(current, publications, exemplary_research, exemplary_teaching, activities, exemplary_service, callback);      
      } else {
        callback();
      }
    }
  
    componentDidMount() {
      this.reset();
      window.scrollTo(0, 0);
    }
  
    componentDidUpdate(prevProps) {
      if (prevProps.semester != this.props.semester) {
        this.setState({ current: 0 }, () => this.reset());
      } else if (prevProps.merit != this.props.merit) {
        this.reset();
      }
    }
  
    reset = () => {
      const { merit, review_type } = this.props;
      
      this.setState({        
        weight_research: this.get_weight("Self", "weight_research"),
        weight_teaching: this.get_weight("Self", "weight_teaching"),
        weight_service: this.get_weight("Self", "weight_service"),
        rationale_weight: merit ? merit.rationale_weight : null,
        notes: merit ? merit.notes : null,
        covid_statement: merit ? merit.covid_statement : null,

        publications: merit && merit.publications && merit.publications?.length > 0 ? merit.publications : [null],
        exemplary_research: merit && merit.exemplary_research && merit.exemplary_research?.length > 0 ? merit.exemplary_research : [null],
        exemplary_teaching: merit && merit.exemplary_teaching && merit.exemplary_teaching?.length > 0 ? merit.exemplary_teaching : [null],
        activities: merit && merit.activities && merit.activities?.length > 0 ? merit.activities : [null],
        exemplary_service: merit && merit.exemplary_service && merit.exemplary_service?.length > 0 ? merit.exemplary_service : [null],
                
        review_committee_weight_research: this.get_weight("Committee", "weight_research"),
        review_committee_weight_teaching: this.get_weight("Committee", "weight_teaching"),
        review_committee_weight_service: this.get_weight("Committee", "weight_service"),
        review_committee_rationale_weight: merit && merit.review_committee ? merit.review_committee.rationale_weight : null,
        review_committee_score_research: merit && merit.review_committee && merit.review_committee.score_research ? merit.review_committee.score_research : merit && review_type == "Committee" ? this.research_score() : null,
        review_committee_rationale_research: merit && merit.review_committee ? merit.review_committee.rationale_research : null,
        review_committee_score_teaching: merit && merit.review_committee && merit.review_committee.score_teaching ? merit.review_committee.score_teaching : merit && review_type == "Committee" ? this.teaching_score() : null,
        review_committee_rationale_teaching: merit && merit.review_committee ? merit.review_committee.rationale_teaching : null,
        review_committee_score_service: merit && merit.review_committee && merit.review_committee.score_service ? merit.review_committee.score_service : merit && review_type == "Committee" ? this.service_score() : null,
        review_committee_rationale_service: merit && merit.review_committee ? merit.review_committee.rationale_service : null,

        review_dean_weight_research: this.get_weight("Dean", "weight_research"),
        review_dean_weight_teaching: this.get_weight("Dean", "weight_teaching"),
        review_dean_weight_service: this.get_weight("Dean", "weight_service"),
        review_dean_rationale_weight: merit && merit.review_dean ? merit.review_dean.rationale_weight : null,
        review_dean_score_research: merit && merit.review_dean && merit.review_dean.score_research ? merit.review_dean.score_research : merit && merit.review_committee && merit.review_committee.score_research && review_type == "Dean" ? merit.review_committee.score_research : merit && review_type == "Dean" ? this.research_score() : null,
        review_dean_rationale_research: merit && merit.review_dean ? merit.review_dean.rationale_research : null,
        review_dean_score_teaching: merit && merit.review_dean && merit.review_dean.score_teaching ? merit.review_dean.score_teaching : merit && merit.review_committee && merit.review_committee.score_teaching && review_type == "Dean" ? merit.review_committee.score_teaching : merit && review_type == "Dean" ? this.teaching_score() : null,
        review_dean_rationale_teaching: merit && merit.review_dean ? merit.review_dean.rationale_teaching : null,
        review_dean_score_service: merit && merit.review_dean  && merit.review_dean.score_service? merit.review_dean.score_service : merit && merit.review_committee && merit.review_committee.score_service && review_type == "Dean" ? merit.review_committee.score_service : merit && review_type == "Dean" ? this.service_score() : null,
        review_dean_rationale_service: merit && merit.review_dean ? merit.review_dean.rationale_service : null,        
      });
    }
    
    get_weight = (level, type) => {
      const { merit } = this.props;

      return merit ? level == "Dean" ? this.state["review_dean_" + type] ? this.state["review_dean_" + type] : merit.review_dean ? merit.review_dean[type] * 100 : this.get_weight("Committee", type) :
                     level == "Committee" ? this.state["review_committee_" + type] ? this.state["review_committee_" + type] : merit.review_committee ? merit.review_committee[type] * 100 : this.get_weight("Self", type) :

                     this.state[type] ? this.state[type] : merit[type] * 100
              : this.state[type] ? this.state[type] : DEFAULT_WEIGHTS[type];
    }
    
    changed_weight = () => {      
      return (this.get_weight("Self", "weight_research") != DEFAULT_WEIGHTS["weight_research"]) ||
             (this.get_weight("Self", "weight_teaching") != DEFAULT_WEIGHTS["weight_teaching"]) ||
             (this.get_weight("Self", "weight_service") != DEFAULT_WEIGHTS["weight_service"]);
    }

    changed_committee_weight = () => {
      return (this.get_weight("Committee", "weight_research") != this.get_weight("Self", "weight_research")) ||
             (this.get_weight("Committee", "weight_teaching") != this.get_weight("Self", "weight_teaching")) ||
             (this.get_weight("Committee", "weight_service") != this.get_weight("Self", "weight_service"));
    }

    changed_dean_weight = () => {
      return (this.get_weight("Dean", "weight_research") != this.get_weight("Committee", "weight_research")) ||
             (this.get_weight("Dean", "weight_teaching") != this.get_weight("Committee", "weight_teaching")) ||
             (this.get_weight("Dean", "weight_service") != this.get_weight("Committee", "weight_service"));
    }
  
    committee_present = () => this.props.merit && this.props.merit.review_committee; 
    dean_present = () =>  this.props.merit && this.props.merit.review_dean;    
    
    generate_weighting = (label, editable, type) => {
      const key = (type == "Committee" ? "review_committee_" : type == "Dean" ? "review_dean_" : "") + 'weight_' + label.toLowerCase();
      return (
        <FormItem {...this.formItemLayout} label={ label } extra={ "Please provide your " + label.toLowerCase() + " weighting." }>
          {this.props.form.getFieldDecorator(key, {
            rules: [{ required: true, message: 'Please enter a value' }],
            initialValue: this.state[key],
            onChange: e => this.setState({ [key]: e }),
          })(<InputNumber disabled={ !editable || this.props.review_type != type } min={0} max={100} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />)}
        </FormItem> );
    }

    generate_weightings = (editable, type) => {
      const { review_type } = this.props;
            
      const key_weight = (type == "Committee" ? "review_committee_" : type == "Dean" ? "review_dean_" : "") + 'weight_research';
      const key = (type == "Committee" ? "review_committee_" : type == "Dean" ? "review_dean_" : "") + 'rationale_weight';
      const required = type == "Committee" ? this.changed_committee_weight() : type == "Dean" ? this.changed_dean_weight() : this.changed_weight();

      const review_exists = this.state[key] != null;
      const being_entered = review_type == type;
      const committee_for_dean = type == "Committee" && review_type == "Dean";
      
      // Don't show weightings that have yet to be entered
      if (type != null && (!being_entered) && (!review_exists) && (!committee_for_dean)) {
        return null;
      }

      return (
        <React.Fragment>
          { type ? (
            <React.Fragment> 
              <Divider orientation="left">{ type } Review</Divider>
              { being_entered ? ( <p>Please enter your { type } review of the proposed weightings.  If you change the proposed weightings, be sure to provide a rationale.  Note that both the weightings and the rationale will be visible to the faculty member you are reviewing.</p> ) : null }
            </React.Fragment>
          ) : null }
          { this.generate_weighting("Research", editable, type) }
          { this.generate_weighting("Teaching", editable, type) }
          { this.generate_weighting("Service", editable, type) }
          <FormItem {...this.formItemLayout} label="Rationale" extra="If you changed the weightings, please provide a brief rationale here.">
            {this.props.form.getFieldDecorator(key, {
              rules: [{ required: required, message: 'Please provide a rationale' }],
              initialValue: this.state[key],
            })(<TextArea disabled={ !editable || !required || !being_entered } rows={ 3 } />)}
          </FormItem>
        </React.Fragment>
      );
    }

    generate_review = (label, editable, type) => {
      const { review_type } = this.props;
      
      const key_score = (type == "Committee" ? "review_committee_" : "review_dean_") + "score_" + label;
      const key_rationale = (type == "Committee" ? "review_committee_" : "review_dean_") + "rationale_" + label;
      
      const review_exists = this.state[key_score] != null;
      const being_entered = review_type == type;
      
      const proper_label = label[0].toUpperCase() + label.slice(1);
      
      // Don't show reviews that have yet to be entered
      if ((!being_entered) && (!review_exists)) {
        return null;
      }
      
      return (
        <React.Fragment>
          <Divider orientation="left">{ type } { proper_label } Review</Divider>
          { being_entered ? ( <p>Please enter the { type } review of the { label } activity.  Note that both the score and the rationale will be visible to the faculty member you are reviewing.</p> ) : null }
          <FormItem {...this.formItemLayout} label="Score" extra={ "Please provide your " + label + " score." }>
            {this.props.form.getFieldDecorator(key_score, {
              rules: [{ required: true, message: 'Please select a score' }],
              initialValue: this.state[key_score],
            })(<Select disabled={ !editable || !being_entered } width={ 150 }>
                { this.scores.map(s => <Option key={ s } value={ s }>{ s }</Option> )}
               </Select>
            )}
          </FormItem>
          <FormItem {...this.formItemLayout} label="Rationale" extra="Please provide a rationale for your score.">
            {this.props.form.getFieldDecorator(key_rationale, {
              rules: [{ required: true, message: 'Please enter a rationale' }],
              initialValue: this.state[key_rationale],
            })(<TextArea disabled={ !editable || !being_entered } rows={ 3 } />)}
          </FormItem>
        </React.Fragment> );
    }    
    
    research_score = () => {
      const { students, merit } = this.props;
      
      const year = parseInt(this.calendar_year(this.props.semesters[0]));
      const semesters_last = this.semester_list().filter(s => s.speed == 1 && this.calendar_year(s.id) == year-1);
      const semesters_this = this.semester_list().filter(s => s.speed == 1 && this.calendar_year(s.id) == year);

      var fundees_last = students.getFundeesYear(semesters_last, merit.instructor);
      var fundees_this = students.getFundeesYear(semesters_this, merit.instructor);
      const fundee_points_last = students.getYearFundeePointsNoMax(merit.instructor, year-1, semesters_last);
      const fundee_points_this = students.getYearFundeePointsNoMax(merit.instructor, year, semesters_this);

      const total_funding = Object.values(fundee_points_last).reduce((r,a) => r+a, 0) + Object.values(fundee_points_this).reduce((r,a) => r+a, 0);
      const is_satisfactory = ((total_funding >= 0.75) || merit.submitted_grants_satisfactory) && (merit.publications.length >= 2);
      const is_exemplary = is_satisfactory && ((total_funding >= 1.5) || merit.lead_pi_exemplary || (merit.exemplary_research.length >= 1));

      return is_exemplary ? this.scores[0] : is_satisfactory ? this.scores[1] : this.scores[2];
    }
    
    section_score = (section, sections) => {
      if ((section.crosslist == null) || (sections.find(s => s.id == section.crosslist) == null)) {
        return section.trace_mean;
      }
      
      const crosslist = sections.find(s => s.id == section.crosslist);
      
      return section.trace_mean && crosslist.trace_mean ? (section.trace_mean + crosslist.trace_mean)/2 : section.trace_mean ? section.trace_mean : crosslist.trace_mean;
    }
    
    teaching_score = () => {
      const { sections } = this.props;
      
      const real_sections = sections.filter(s => !s.deleted && (s.crosslist == null || s.id < s.crosslist) && (s.loadcount == 1.0));
      
      const sections_lt_35 = real_sections.filter(s => this.section_score(s, sections) < 3.5);
      const sections_gte_35 = real_sections.filter(s => this.section_score(s, sections) >= 3.5);
      const sections_gte_40 = real_sections.filter(s => this.section_score(s, sections) >= 4.0);
      const sections_gte_45 = real_sections.filter(s => this.section_score(s, sections) >= 4.5);
      
      const is_satisfactory = (sections_gte_35.length >= 2) || (sections_gte_35.length == real_sections.length);
      const is_exemplary = is_satisfactory && ((((sections_gte_45.length >= 2) || (sections_gte_45.length == real_sections.length)) && sections_lt_35.length == 0) || sections_gte_40.length >= 4);
                            
      return is_exemplary ? this.scores[0] : is_satisfactory ? this.scores[1] : this.scores[2];
    }
    
    service_score = () => {
      const { merit } = this.props;
      
      const year = parseInt(this.calendar_year(this.props.semesters[0]));
      const is_research_active = this.research_score() != this.scores[2];
      const scores_last = this.committeeyear_list().filter(cy => (cy.year == year) && cy.members.find(m => m.instructor == merit.instructor)).map(cy => cy.members.find(m => m.instructor == merit.instructor).chair ? this.scores[1] : cy.members.find(m => m.instructor == merit.instructor).score);
      const scores_this = this.committeeyear_list().filter(cy => (cy.year == year+1) && cy.members.find(m => m.instructor == merit.instructor)).map(cy => cy.members.find(m => m.instructor == merit.instructor).chair ? this.scores[1] : cy.members.find(m => m.instructor == merit.instructor).score);
            
      const is_satisfactory = merit.activities.length >= 1 &&
                              ((is_research_active && scores_last.filter(s => s != this.scores[2]).length >= 1 && scores_this.filter(s => s != this.scores[2]).length >= 1) ||
                               (!is_research_active && scores_last.filter(s => s != this.scores[2]).length >= 2 && scores_this.filter(s => s != this.scores[2]).length >= 2));
      const is_exemplary = is_satisfactory && (
                            (scores_last.filter(s => s == this.scores[0]).length >= 1 && scores_this.filter(s => s == this.scores[0]).length >= 1) ||
                            (scores_last.filter(s => s != this.scores[2]).length >= 3 && scores_this.filter(s => s != this.scores[2]).length >= 3) ||
                            merit.exemplary_service?.length > 0);
      
      return is_exemplary ? this.scores[0] : is_satisfactory ? this.scores[1] : this.scores[2];
    }
    
    render_committee_review = (membership, editable) => {
      return ( <List key={ "list-" + membership.id }
                     grid={ this.grid_preference }
                     size="small"
                     dataSource={ [1] }
                     renderItem={ record => [(
                       <List.Item key={ "item" }>
                         <List.Item.Meta title={ this.print_full_instructor(membership.instructor) } />
                       </List.Item> ), (
                       <List.Item key={ "pref" } style={{float: 'right'}}>
                         <Preference {...this.props}
                                value={ membership }
                                editable={ editable }
                                onChange={ this.props.updateCommitteeMemberships }
                                endpoint="/api/merit/committee/"
                                defaultValue={ null }
                                fieldName="score"
                                options={ this.scores.map(s => { return { id: s, text: s }; }) } />
                       </List.Item> )] } />
              );      
    }
    
    render() {
      const { visible, onCancel, onCreate, form, merit, sections, students, disabled, review_type, return_link } = this.props;
      const { getFieldDecorator } = form;
      const { current, weight_research, weight_teaching, weight_service, rationale_weight, publications, exemplary_research, exemplary_teaching, activities, exemplary_service, notes, covid_statement, review_committee_weight_research, review_committee_weight_teaching, review_committee_weight_service, review_committee_rationale_weight, review_dean_weight_research, review_dean_weight_teaching, review_dean_weight_service, review_dean_rationale_weight, review_committee_score_research, review_committee_rationale_research, review_committee_score_teaching, review_committee_rationale_teaching, review_committee_score_service, review_committee_rationale_service, review_dean_score_research, review_dean_rationale_research, review_dean_score_teaching, review_dean_rationale_teaching, review_dean_score_service, review_dean_rationale_service } = this.state;
          
      const single_page = (review_type == null) && this.dean_present();

      const editable = !this.committee_present() && !this.dean_present() && !disabled;
      const years = [this.get_year(), this.props.semester.includes("Fall") ? this.get_year() - 1 : this.get_year() + 1];
      years.sort();
      
      const comm_posn = merit ? [<CommitteeCard {...this.props} year={ years[0] } instructor={ merit.instructor } show_score={ true } />, <CommitteeCard {...this.props} year={ years[1] } instructor={ merit.instructor } show_score={ true } />, <PositionCard {...this.props} year={ years[0] } instructor={ merit.instructor } />, <PositionCard {...this.props} year={ years[1] } instructor={ merit.instructor } />] : [];
      
      const year = this.calendar_year(this.props.semesters[0]);
      const semesters = this.semester_list().filter(s => s.speed == 1 && this.calendar_year(s.id) == year);
      const prev_semesters = this.semester_list().filter(s => s.speed == 1 && this.calendar_year(s.id) == year-1);
      semesters.reverse();
      prev_semesters.reverse();
        
      const steps = [
        {
          title: 'Basics',
          disabled: false,
          content: (
            <Form>
              <div className="no-print">
                <p> </p><p>In this first step, you will review your expected weighting of responsibilities, as well as provide information on special circumstances that may be present.</p>
  
                <Divider orientation="left">Weightings</Divider>
                <p>The standard weightings for faculty are 40% research, 40% teaching, and 20% service.  If you disagree with these weightings, you may adjust them here, but you must provide a rationale for doing so (that will be reviewed by the Merit Committee and the Dean).  Common reasons for adjustments are leaves of abscence or leadership positions within the College.</p>
                { this.generate_weightings(editable, null) }
                
                <Divider orientation="left">Special Circumstances</Divider>
                <p>If there are any special circumstances the Merit Committee should be aware of, please list them below.</p>
                <FormItem {...this.formItemLayout} label="Special Circumstances" extra="Please enter any special circumstances here.">
                  {getFieldDecorator('notes', {
                    initialValue: notes,
                  })(<TextArea disabled={ !editable || review_type != null } rows={ 3 } />)}
                </FormItem>
                <Divider orientation="left">COVID-19 Impact</Divider>
                <p>The Merit Committee will consider the impact of the pandemic on teaching, research, and service during their review of faculty member’s merit materials by looking specifically at the ways that indicators of each workload element were impacted. Please provide a brief description of the specific impact (e.g. low TRACE scores, or reduced scholarship) and the reasons (e.g. remote teaching or graduate students losing focus) for the impact. In considering the reasons, the Merit Committee may opt to provide a higher rating for the workload area than might have been given in past years.</p>
                <FormItem {...this.formItemLayout} label="COVID Impact Statement" extra="Please enter an optional impact statement here.">
                  {getFieldDecorator('covid_statement', {
                    initialValue: covid_statement,
                  })(<TextArea disabled={ !editable || review_type != null } rows={ 8 } />)}
                </FormItem>
                {((merit?.review_dean && merit?.review_committee && merit?.merit_pdfs) || (review_type && merit?.merit_pdfs)) && (
                  <>
                    <Divider orientation="left">Supporting Documents</Divider>
                      {merit.merit_pdfs?.map((file) => (
                        <div style={{paddingBottom:'25px'}}>
                          <a key={file.id} onClick={() => this.openPDF(file.file, `Merit-${file.year}-${this.print_instructor(file.faculty)}.pdf`)}>
                            <Icon type="file-pdf" theme="twoTone" twoToneColor="#eb2f96" />{" " + file.file_type + ".pdf"}
                          </a>
                        </div>
                    ))}
                  </>
                )}
              </div>
              
              { this.generate_weightings((!disabled) && (review_type == "Committee") && (!this.dean_present()), "Committee") }
              { this.generate_weightings((!disabled) && (review_type == "Dean"), "Dean") }
             
            </Form>  
          ),
        },
        {
          title: 'Research',
          disabled: false,
          content: (
            <Form>
              <div className="no-print">
                <p> </p><p>In this step, you will review and enter information related to your research activities.</p>
              
                <Divider orientation="left">Advising</Divider>
                <p>Below is a list of all students we have a record of you advising during the review year, as well as the previous year (as these are both used to calculate your score).  <b>If any Ph.D. students who you advised during this year are missing from this table, please contact the <a href="mailto:khoury-graduateprograms@northeastern.edu">Khoury Graduate Office</a> in order to get the data corrected.</b>  </p>
  
                { merit ? (
                  <div>
                    <p><b>{ (year-1) } Calendar Year</b></p>
                    <PhDStudentAdvisingPointsTable {...this.props} data={ students } semesters={ prev_semesters } year={ year-1 } instructor={ merit.instructor } show_points={ review_type == "Committee" || review_type == "Dean" } />
                    <p/>
                    <p><b>{ year } Calendar Year</b></p>
                    <PhDStudentAdvisingPointsTable {...this.props} data={ students } semesters={ semesters } year={ year } instructor={ merit.instructor } show_points={ review_type == "Committee" || review_type == "Dean" } />
                  </div>
                ) : null }
    
                <Divider orientation="left">Funding</Divider>
                <p>Below is a list of all students we have a record of you funding during the review year, as well as the previous year (as these are both used to calculate your score).  <b>If any Ph.D. students who you funded during this year are missing from here, please contact the <a href="mailto:khoury-grants@northeastern.edu">Khoury Grants Team</a> in order to investigate the issue</b> (as this may indicate they were incorrectly funded from a different location).  </p>
  
                { merit ? (
                  <div>
                    <p><b>{ (year-1) } Calendar Year</b></p>
                    <PhDStudentFundingPointsTable {...this.props} data={ students } semesters={ prev_semesters } year={ year-1 } instructor={ merit.instructor } show_points={ review_type == "Committee" || review_type == "Dean" } />
                    <p/>
                    <p><b>{ year } Calendar Year</b></p>
                    <PhDStudentFundingPointsTable {...this.props} data={ students } semesters={ semesters } year={ year } instructor={ merit.instructor } show_points={ review_type == "Committee" || review_type == "Dean" } />
                  </div>
                ) : null }
                
                <Divider orientation="left">Publications</Divider>
                <p>Please list up to five of your most significant publications that are in competitive high-quality venues over the last two years.</p>
                { publications ? publications.map((e, idx) =>
                  <div key={ idx }><FormItem {...this.formItemLayout} label={ "Title " + (idx+1) } extra="Please enter the title of your publication.">
                    {getFieldDecorator("publication_title" + idx, {
                      initialValue: e ? e.title : null,
                      })(<Input disabled={ !editable || review_type != null } width={ 250 } />)}
                  </FormItem>
                  <FormItem {...this.formItemLayout} label={ "Venue " + (idx+1) } extra="Please enter the venue where it appeared.">
                  {getFieldDecorator("publication_venue" + idx, {
                    initialValue: e ? e.venue : null,
                    })(<Input disabled={ !editable || review_type != null } width={ 250 } />)}
                  </FormItem>
                  { idx < publications.length-1 ? <Divider dashed /> : null }</div>
                  ) : null }
                { publications && (publications.length < MAX_PUBLICATIONS && !review_type) ? (
                    <FormItem {...this.formItemLayout} className="no-print" label=" " colon={ false }>
                      <Button disabled={ !editable } icon="plus" onClick={ () => this.setState({ publications: publications.concat([null,]) }) }>Add Publication</Button>
                    </FormItem>
                  ) : null }
      
                <Divider orientation="left">Exemplary Activities</Divider>
                <p>If you have had any exemplary research activities (for example, a Best Paper Award at top-tier conference), please list up to three of them here.</p>
                { exemplary_research ? exemplary_research.map((e, idx) =>
                  <FormItem {...this.formItemLayout} key={ idx } label={ "Thing " + (idx+1) } extra="Please enter the exemplary activity or result.">
                    {getFieldDecorator("exemplary_research" + idx, {
                      initialValue: e ? e.title : null,
                      })(<TextArea disabled={ !editable || review_type != null } rows={ 4 } />)}
                  </FormItem>
                ) : null }
                { exemplary_research && (exemplary_research.length < MAX_EXEMPLARY) && !review_type? (
                    <FormItem {...this.formItemLayout} className="no-print" label=" " colon={ false }>
                      <Button disabled={ !editable } icon="plus" onClick={ () => this.setState({ exemplary_research: exemplary_research.concat([null,]) }) }>Add Exemplary Thing</Button>
                    </FormItem>
                  ) : null }
  
                <Divider orientation="left">Grants Information</Divider>
                <p>Below is information provided by Khoury Grants relevant to the merit process.  If you have any questions on this data, please contact the <a href="mailto:khoury-grants@northeastern.edu">Khoury Grants</a> team.</p>
                <FormItem {...this.formItemLayout} label={ "Submitted grants" } extra="Whether or not the faculty member submitted grants cumulatively providing 3 semesters of PhD funding in the last two years.">
                  {getFieldDecorator("submitted_grants_satisfactory", {
                      initialValue: merit ? merit.submitted_grants_satisfactory : null,
                      })(<Select disabled={ true } width={ 100 }>
                        <Option value={ null }>{ "Not Yet Entered" }</Option> 
                        <Option value={ true }>{ "Yes" }</Option> 
                        <Option value={ false }>{ "No" }</Option> 
                      </Select>)}
                </FormItem>
  
                <FormItem {...this.formItemLayout} label={ "Lead PI on large grant" } extra="Whether or not the faculty member is the lead PI on new multi-million-dollar grant awarded last year.">
                  {getFieldDecorator("lead_pi_exemplary", {
                      initialValue: merit ? merit.lead_pi_exemplary : null,
                      })(<Select disabled={ true } width={ 100 }>
                        <Option value={ null }>{ "Not Yet Entered" }</Option> 
                        <Option value={ true }>{ "Yes" }</Option> 
                        <Option value={ false }>{ "No" }</Option> 
                      </Select>)}
                </FormItem>
              </div>

              { this.generate_review("research", !disabled && review_type == "Committee" && (!this.dean_present()), "Committee", review_type) }
              { this.generate_review("research", !disabled && review_type == "Dean", "Dean", review_type) }
            </Form>
          ),
        },
        {
          title: 'Teaching',
          disabled: false,
          content: (
            <Form>
              <div className="no-print">
                <p> </p><p>In this step, you will review and enter information related to your teaching activities.</p>
  
                <Divider orientation="left">Courses Taught</Divider>
                <p>Below is a list of all Khoury courses you have taught during the review period.  Note that we will not have courses taught in other Colleges; if you taught a course in another College as part of your Khoury teaching load (e.g., an HONR class), be sure to provide details in the Special Circumstances box on in first step.</p>
                <SectionTable {...this.props} data={ sections } hide_fields={ ["actions"] } show_fields={ ["trace", "campus"] } hide_links={ true } />
                
                <Divider orientation="left">Exemplary Activities/Results</Divider>
                <p>If you have had any exemplary teaching activities or results from the past year, please list up to three of them here.</p>
                { exemplary_teaching ? exemplary_teaching.map((e, idx) =>
                  <FormItem {...this.formItemLayout} key={ idx } label={ "Thing " + (idx+1) } extra="Please enter the exemplary activity or result.">
                    {getFieldDecorator("exemplary_teaching" + idx, {
                      initialValue: e ? e.title : null,
                      })(<TextArea disabled={ !editable || review_type != null } rows={ 4 } />)}
                  </FormItem>
                ) : null }
                { exemplary_teaching && (exemplary_teaching.length < MAX_EXEMPLARY) && !review_type? (
                    <FormItem {...this.formItemLayout} className="no-print" label=" " colon={ false }>
                      <Button disabled={ !editable } icon="plus" onClick={ () => this.setState({ exemplary_teaching: exemplary_teaching.concat([null,]) }) }>Add Exemplary Thing</Button>
                    </FormItem>
                  ) : null }
              </div>

              { this.generate_review("teaching", !disabled && review_type == "Committee" && (!this.dean_present()), "Committee", review_type) }
              { this.generate_review("teaching", !disabled && review_type == "Dean", "Dean", review_type) }
            </Form>
          ),
        },
        {
          title: 'Service',
          disabled: false,
          content: (
            <Form>
              <div className="no-print">
                <p> </p><p>In this step, you will review and enter information related to your service activities.</p>
  
                <Divider orientation="left">Khoury Committees and Positions</Divider>
                <p>Below is a list of all Khoury committees we have you listed as a member of, along with a list of all positions we have you recorded as holding.  If any committee memberships or positions are missings, please email <a href="mailto:khoury-academicaffairs@northeastern.edu">Khoury Academic Affairs</a>.</p>
                <List grid={ this.grid } dataSource={ comm_posn } renderItem={(item, idx) => ( <List.Item key={ idx }>{ item }</List.Item> )} />
      
                <Divider orientation="left">Other Activities</Divider>
                <p>Please list up to five of your most significant professional service activities for the past year that are not included in the list above.  These can include serving on the Program Committee of a competitive research conference or participation in a NSF, NIH, or comparable proposal review panel.</p>
                { activities ? activities.map((e, idx) =>
                  <FormItem {...this.formItemLayout} key={ idx } label={ "Activity " + (idx+1) } extra="Please enter the professional activity.">
                    {getFieldDecorator("activity" + idx, {
                      initialValue: e ? e.title : null,
                      })(<Input disabled={ !editable || review_type != null } width={ 250 } />)}
                  </FormItem>
                ) : null }
                { activities && (activities.length < MAX_ACTIVITIES) && !review_type ? (
                    <FormItem {...this.formItemLayout} className="no-print" label=" " colon={ false }>
                      <Button disabled={ !editable } icon="plus" onClick={ () => this.setState({ activities: activities.concat([null,]) }) }>Add Activity</Button>
                    </FormItem>
                  ) : null }
      
                <Divider orientation="left">Exemplary Activities/Results</Divider>
                <p>If you have had any exemplary service activities or results from the past year (e.g., chairing a major Khoury or Northeastern committee, General or PC Chair of a top-tier conference, or editor of a major journal), please list up to three of them here.</p>
                { exemplary_research ? exemplary_service.map((e, idx) =>
                  <FormItem {...this.formItemLayout} key={ idx } label={ "Thing " + (idx+1) } extra="Please enter the exemplary activity or result.">
                    {getFieldDecorator("exemplary_service" + idx, {
                      initialValue: e ? e.title : null,
                      })(<TextArea disabled={ !editable || review_type != null } rows={ 4 } />)}
                  </FormItem>
                ) : null }
                { exemplary_service && (exemplary_service.length < MAX_EXEMPLARY) && !review_type ? (
                    <FormItem {...this.formItemLayout} className="no-print" label=" " colon={ false }>
                      <Button disabled={ !editable } icon="plus" onClick={ () => this.setState({ exemplary_service: exemplary_service.concat([null,]) }) }>Add Exemplary Thing</Button>
                    </FormItem>
                  ) : null }
              </div>

              { this.generate_review("service", !disabled && review_type == "Committee" && (!this.dean_present()), "Committee", review_type) }
              { this.generate_review("service", !disabled && review_type == "Dean", "Dean", review_type) }
            </Form>
          ),
        },
      ];
      
      const chairs = this.committeeyear_list().filter(cy => ((cy.year == parseInt(year)) || (cy.year == parseInt(year)+1)) && merit && cy.members.find(m => (m.instructor == merit.instructor) && m.chair));
      if ((!review_type) && chairs?.length > 0 && !single_page) {
        steps.push({
          title: 'Committees',
          disabled: false,
          content: (
            <Form>
              <p> </p><p>In this step, you will review the members of the committees that you chaired during this year and last.  These ratings are used as part of the merit scores for the members.  Note that there is a single score for each of the committee members; thus, if there is a co-Chair of your committee(s), you may see their scores below (and they will see yours). Additionally note that the score you assign to each faculty member is made visible to them.</p>

              { chairs.map(cy => {
                
                return (
                  <React.Fragment>
                    <Divider orientation="left">{ this.print_year(cy.year) } { this.print_committee(cy.committee) }</Divider>
                    <p>Please provide (or update) ratings for the members of the { this.print_committee(cy.committee) } during the { this.print_year(cy.year) } year.</p>
                    
                    { add_dividers_horiz(cy.members.filter(m => !m.chair).map(cm => this.render_committee_review(cm, editable))) }
                    
                  </React.Fragment>
                );
              })}

            </Form>
          ),
        });
      }
    
      return single_page ? (
        <>
          { steps.map(item => item.content) }
          <Form>
            <Divider orientation="left">Score</Divider>
            <p>Your overall merit is a weighted average of the research, teaching, and service scores from Merit Committee (weighted at 2/3) and Dean (weighted at 1/3).  This score is presented below.</p>
            <FormItem {...this.formItemLayout} label={ "Score" } extra="This is your overall merit score.">
              <NumberFormat displayType="text" decimalScale={ 2 } fixedDecimalScale={ true } value={ get_merit_score(merit) } />
            </FormItem>
          </Form>
        </>
      ) : (
        <div>
          { review_type == null && merit && merit.complete && current === 0 && editable ? 
            ( <div><Alert message="Merit Report Submitted" description={ "You have submitted your Merit report but you are allowed to edit it until the Merit Committee convenes and begins its reviews." } type="success" showIcon /><p/></div> ): null }
          { review_type == null && merit && merit.complete && !editable && !disabled ? 
            ( <div><Alert message="Merit Report Submitted and Under Review" description={ "You have submitted your Merit report but the Merit Committee has begun reviews; thus it is read-only." } type="success" showIcon /><p/></div> ): null }
          { review_type == null && current === steps.length-1 && editable ? 
            ( <div><Alert message={ merit && merit.complete ? "Edits Not Saved" : "Not Yet Submitted"} description={ "Please make sure to hit the Done button at the bottom of this page to save your review." } type="warning" showIcon /><p/></div> ): null }

          <Steps current={current}>
            {steps.map((item, idx) => (
              <Step key={item.title} title={item.title} disabled={item.disabled} icon={ <span>{ idx+1 }</span> }/>
            ))}
          </Steps>
          <div className="steps-content">{steps[current].content}</div>
          <div className="steps-action">
            {current < steps.length-1 && (
              <Button type="primary" onClick={() => this.next()}>
                Next
              </Button>
            )}
            {current === steps.length-1 && (
              <Button type="primary" onClick={() => { this.save(() => { window.scrollTo(0, 0); if (return_link) { return_link(); } else { this.setState({ current: 0 }) } }) } }>
                Done
              </Button>
            )}
            {current > 0 && (
              <Button style={{ marginLeft: 8 }} onClick={() => this.prev()}>
                Previous
              </Button>
            )}
            {return_link && (
              <Button style={{ marginLeft: 8 }} onClick={ return_link }>
                Return to List
              </Button>
            )}
            <div className="ant-form-extra">Changes are saved each time you click Next or Previous.</div>
          </div>
        </div>
      );
    }
  }
);

class Merit extends AppComponent {
  state = {
    endpoint: "/api/merit/faculty/",
    merit: null,
    loading_merit: true,

    endpoint_schedule: "/api/schedule/",
    sections: [],
    loading_schedule: true,
    
    endpoint_students: "/api/phd/",
    students: new PhDStudentData([], this.props),
    loading_students: true,

  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.semester != this.props.semester) {
      this.getData();
    }
  }

  getData = () => {
    this.doGet(this.state.endpoint_schedule + "?instructors=" + this.props.user.person.id + "&semester=" + this.semester_list().filter(s => this.calendar_year(s.id) == this.getMeritYear()).map(s => s.id).join(","),
                  data => this.setState({ sections: data, loading_schedule: false }));
    this.doGet(this.state.endpoint_students,
                  data => this.setState({ students: new PhDStudentData(data, this.props), loading_students: false }));
                  
    this.getMerit();
  }

  getMeritYear = () => this.calendar_year(this.props.semesters[0]);

  getMerit = () => {
    this.doGet(this.state.endpoint + "?instructor=" + this.props.user.instructor + "&year=" + this.getMeritYear(), data => this.setState({ merit: data?.length > 0 ? data[0] : null, loading_merit: false }));
  }

  handleCreateUpdate = (current, publications, exemplary_research, exemplary_teaching, activities, exemplary_service, callback) => {
    const form = this.formRef.props.form;
    const { merit } = this.state;

    form.validateFields((err, values) => {
      if (err) { return; }
      
      values.weight_research = values.weight_research/100.0;
      values.weight_teaching = values.weight_teaching/100.0;
      values.weight_service = values.weight_service/100.0;

      values.publications = publications.map((e, idx) => { return { title: values["publication_title" + idx], venue: values["publication_venue" + idx] }; }).filter(e => e.title || e.venue);
      Object.keys(values).forEach(e => e.match("publication_title([0-9]+)") ? delete values[e] : null);
      Object.keys(values).forEach(e => e.match("publication_venue([0-9]+)") ? delete values[e] : null);

      values.exemplary_research = exemplary_research.map((e, idx) => { return { title: values["exemplary_research" + idx] }; }).filter(e => e.title);
      Object.keys(values).forEach(e => e.match("exemplary_research([0-9]+)") ? delete values[e] : null);

      values.exemplary_teaching = exemplary_teaching.map((e, idx) => { return { title: values["exemplary_teaching" + idx] }; }).filter(e => e.title);
      Object.keys(values).forEach(e => e.match("exemplary_teaching([0-9]+)") ? delete values[e] : null);

      values.activities = activities.map((e, idx) => { return { title: values["activity" + idx] }; }).filter(e => e.title);
      Object.keys(values).forEach(e => e.match("activity([0-9]+)") ? delete values[e] : null);

      values.exemplary_service = exemplary_research.map((e, idx) => { return { title: values["exemplary_service" + idx] }; }).filter(e => e.title);
      Object.keys(values).forEach(e => e.match("exemplary_service([0-9]+)") ? delete values[e] : null);
      
      if (! merit) {
        values.complete = false;
      } else if (current == 3) {
        values.complete = true;
      }
      
      values.instructor = this.props.user.instructor;
      values.year = this.getMeritYear();

      if (merit) {
        this.doPatch(this.state.endpoint + merit.id + "/", (result) => { if (result) { this.getMerit(); callback(); } }, JSON.stringify(values));
      } else {
        this.doPost(this.state.endpoint, (result) => { if (result) { this.getMerit(); callback(); } }, JSON.stringify(values));
      }
    });
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  render() {
    const { merit, loading_students, loading_merit, loading_schedule, sections, students } = this.state;
    
    const loading = loading_merit || loading_students || loading_schedule;
    
    const disabled = this.getMeritYear() != moment().format('YYYY')-1;
    const alert = this.getMeritYear() >= moment().format('YYYY') ? ( <Alert message="Year not yet finished" description={ "The " + this.getMeritYear() + " year has not finished yet, so you cannot submit your merit report just yet." } type="warning" showIcon /> ) : this.getMeritYear() < moment().format('YYYY')-1 ? ( <Alert message="Year now archived" description={ "The " + this.getMeritYear() + " year is now archived; you may view but not edit your merit report." } type="warning" showIcon /> ) : null;    

    return (
      <Content {...this.props} title={ this.getMeritYear() + " Merit Report for " + this.print_full_instructor(this.props.user.instructor) } breadcrumbs={ [{ link: "/faculty", text: "Faculty" }, { text: "Merit" }] }>
        <p>The Khoury College Merit process is an annual process that is used as input to the annual evaluation performed each year by the Dean.  The process was proposed, voted on, and approved by the faculty; it is now a part of the College's By-Laws.  You can see an overview of the process <a target="_blank" href="https://github.khoury.northeastern.edu/faculty/khoury-bylaws/blob/master/ttt/merit.md">on the College GitHub</a>.  In brief, the faculty fill out the form below, and the Merit Committee then reviews the faculty entry and makes any adjustments it feels are necessary.  Finally, the Dean reviews the faculty and Merit Committee entries and makes any adjustments; the result is the final score for the faculty member.</p>
        <p className="no-print">The form below is divided into six steps.  The first four steps consist of the faculty reviewing data used for merit and providing addition information to the Merit Committee.  Once this is done, the Merit Committee will review the faculty member's report and will provide updated scores.  This is the fifth step, and will not be visible to faculty members until the Merit Committee has completed its work.  Finally, the Dean will review the entries and provide final scores; this will also not be visible to the faculty members until the Dean has completed their work.</p>
        
        { alert }
        
        <p> </p>
        
        { loading ? <Spin tip="Loading merit data" /> : <MeritForm {...this.props} merit={ merit } sections={ sections } students={ students } handleCreateUpdate={ this.handleCreateUpdate } wrappedComponentRef={ this.saveFormRef } disabled={ disabled } /> }
      </Content>
    );
  }
}

const MeritPDF = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    state = {
      uploadedList: [],
    }

    handleFileUpdate = (fileData) => {
      if (fileData) {
          this.setState({ uploadedList: [fileData] });  // Add or update the file data
      } else {
          this.setState({ uploadedList: [] });  // Clear the file data
      }
    };

    reset = () => {
      this.props.form.resetFields();
      this.setState({ uploadedList: [] });
    }

    handleSubmit = () => {
      const { form, meritYear, updateState, endpoint_file_upload, selectedFaculty} = this.props;
      const { uploadedList } = this.state;

      form.validateFields((err, values) => {
        if (err) { return; }

        const data = {
          "file": uploadedList[0]["fileData"], // If uploadedList in the format: "uploadedList" = [{"fileData": file, "type": type}] 
          "year": meritYear,
          "faculty": selectedFaculty
        };

        const action = "add";

        this.doAction(endpoint_file_upload, data, action, (updatedData) => {
          updateState(updatedData, action);
          this.reset();
        });
      });
    };

    

    render() {
      const { form, disabled, merit_pdfs, selectedFaculty, handleDelete } = this.props;
      const filtered_pdfs = merit_pdfs?.filter(m => m.faculty == selectedFaculty)
      const disableSubmit = filtered_pdfs.length > 0

      const columns = [
        {
          title: "PDF",
          key: "pdf",
          width: 200,
          render: (text, record, idx) => (<a onClick={() => this.openPDF(record.file, "merit " + record.year + ".pdf")}><Icon type="file-pdf" theme="twoTone" twoToneColor="#eb2f96" />{" " + record.file_type + ".pdf"}</a>)
        },
        {
          title: "Actions",
          key: "actions",
          width: 100,
          render: (text, record, idx) => (
            <span>
              <>
                {/* <Button type="primary" onClick={() => this.toggleModal(record, true)}>Edit</Button>
                <Divider type="vertical" /> */}
                <Button type="danger" onClick={() => handleDelete(record)}>Delete</Button>
              </>
            </span>
          )
        },
      ];  
            
      return (
        <>
          <div >
            {filtered_pdfs.length > 0 && 
            <Table
              dataSource={filtered_pdfs}
              columns={columns}
              pagination={false}
            ></Table>
            }
            <Form >
              <p>If there is a supporting document from another NU chair (outside of Khoury) that states the contributions of this faculty member, please upload below. </p>
              <strong>Note: You can only upload one document per field. It must be a PDF and no larger than 10 MB.</strong>

              <FileUpload
                label="Merit PDF"
                type="Merit PDF"
                description="Please submit the supporting document as a PDF. Max 10MB."
                form={form}
                onFileUpdate={this.handleFileUpdate}
                uploadedList={this.state.uploadedList}           
                required={true}
              />

              <FormItem {...this.formItemLayout} label=" " colon={false} extra={disableSubmit ? "To submit again, delete the previously submitted form." : ""}>
                <Button type="primary" disabled={disableSubmit} htmlType="submit" onClick={() => this.handleSubmit()}>
                  Submit
                </Button>
              </FormItem>
            </Form>
          </div>
        </>
      );
    }
  }
);

class MeritList extends AppComponent {
  state = {
    endpoint: "/api/merit/faculty/",
    merits: [],
    loading_merit: true,
    
    endpoint_review: "/api/merit/review/",

    endpoint_schedule: "/api/schedule/",
    sections: [],
    loading_schedule: true,

    endpoint_students: "/api/phd/",
    students: new PhDStudentData([], this.props),
    loading_students: true,
    
    merit_pdfs: [],
    faculty_modal: null,
    endpoint_file_upload: "/api/merit/file/",
    loading_files: true,

    visible: false,
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.semester != this.props.semester) {
      this.getData();
    }
  }
  
  getData = () => {
    this.doGet(this.state.endpoint_schedule + "?semester=" + this.semester_list().filter(s => this.calendar_year(s.id) == this.getMeritYear()).map(s => s.id).join(","),
                  data => this.setState({ sections: data, loading_schedule: false }));
    this.doGet(this.state.endpoint_students,
                  data => this.setState({ students: new PhDStudentData(data, this.props), loading_students: false }));
    this.doGet(this.state.endpoint_file_upload + "?year=" + this.getMeritYear(),
                  data => this.setState({ merit_pdfs: data, loading_files: false }));  
    this.getMerit();
  }

  getMerit = () => {
    this.doGet(this.state.endpoint + "?year=" + this.getMeritYear(), data => this.setState({ merits: data, loading_merit: false, selected_merit: this.state.selected_merit ? data.find(m => m.id == this.state.selected_merit.id) : null }));
  }

  getMeritYear = () => this.calendar_year(this.props.semesters[0]);

  getScore = (merit) => {
    const score = get_merit_score(merit);
    return score != null ? ( <NumberFormat displayType="text" decimalScale={ 2 } fixedDecimalScale={ true } value={ score } /> ) : "-";    
  }

  updateState = (updatedData, action) => {
    this.setState(
      (prevState) => {
        const updatedMeritPdfs = manageStateItems(prevState, 'merit_pdfs', action, updatedData);
        return { ...updatedMeritPdfs};
      }
    );
  }

  handleDelete = (e) => {
    const { endpoint_file_upload } = this.state;
    this.doDelete(`${endpoint_file_upload}${e.id}/?year=${this.getMeritYear()}`, () => {
        this.updateState(e, 'delete');
    }, JSON.stringify());
}

  handleCreateUpdate = (current, publications, exemplary_research, exemplary_teaching, activities, exemplary_service, callback) => {
    const form = this.formRef.props.form;
    const { selected_merit, endpoint_review } = this.state;
    const { review_type } = this.props;

    const check_fields = current == 0 ? ["weight_research", "weight_teaching", "weight_service", "rationale_weight"] :
                         current == 1 ? ["score_research", "rationale_research"] :
                         current == 2 ? ["score_teaching", "rationale_teaching"] : ["score_service", "rationale_service"];

    const review_key = review_type == "Committee" ? "review_committee" : "review_dean";
    const my_fields = check_fields.map(f => review_key + "_" + f); //Object.keys(form.getFieldsValue()).filter(k => k.includes(review_key));


    form.validateFields(my_fields, (err, values) => {
      if (err) { return; }
      const data = {};
      
      check_fields.forEach(k => data[k] = values[review_key + "_" + k]);

      if ("weight_research" in data) { 
        data.weight_research = data.weight_research/100.0; 
        data.weight_teaching = data.weight_teaching/100.0;
        data.weight_service = data.weight_service/100.0;
      }

      if (selected_merit[review_key]) {
        this.doPatch(this.state.endpoint_review + selected_merit[review_key].id + "/", (result) => { if (result) { this.getMerit(); callback(); } }, JSON.stringify(data));
      } else {
        data.instructor = selected_merit.instructor;
        data.year = selected_merit.year;
        data.reviewer = review_type;
        this.doPost(this.state.endpoint_review, (result) => { if (result) { this.getMerit(); callback(); } }, JSON.stringify(data));
      }
    });
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }
  
  meritComplete = (merit) => {
    return merit && merit.complete;
  }

  toggleModal = ( faculty_id, visible) => {
    this.setState({ faculty_modal: faculty_id, visible: visible });
  };

  render() {
    const { review_type, semesters } = this.props;
    const { merits, loading_merit, loading_schedule, sections, selected_merit, students, loading_students, visible, merit_pdfs,  faculty_modal} = this.state;
        
    const loading = loading_merit || loading_schedule || loading_students;  
    const meritYear = this.getMeritYear() 
        
    const disabled = this.getMeritYear() != moment().format('YYYY')-1;
    const alert = this.getMeritYear() >= moment().format('YYYY') ? ( <Alert message="Year not yet finished" description={ "The " + this.getMeritYear() + " year has not finished yet, so you cannot submit your merit reviews just yet." } type="warning" showIcon /> ) : this.getMeritYear() < moment().format('YYYY')-1 ? ( <Alert message="Year now archived" description={ "The " + this.getMeritYear() + " year is now archived; you may view but not edit your merit reviews." } type="warning" showIcon /> ) : null;    

    const review_key = review_type == "Committee" ? "review_committee" : "review_dean";

    const columns = [{
      title: 'Name',
      key: 'name',
      render: (text, record, idx) => this.link_full_instructor(record.id),
    }, {
      title: 'Self-Review',
      key: 'self-review',
      align: 'center',
      width: 100,
      render: (text, record, idx) => get_check_nocheck(this.meritComplete(merits.find(m => m.instructor == record.id))),
    }, {
      title: 'Committee',
      children: [{
        title: 'Research',
        key: 'committee-research',
        align: 'center',
        width: 120,
        render: (text, record, idx) => get_merit_tag(merits.find(m => m.instructor == record.id) && merits.find(m => m.instructor == record.id).review_committee ? merits.find(m => m.instructor == record.id).review_committee.score_research : null),
      }, {
        title: 'Teaching',
        key: 'committee-teaching',
        align: 'center',
        width: 120,
        render: (text, record, idx) => get_merit_tag(merits.find(m => m.instructor == record.id) && merits.find(m => m.instructor == record.id).review_committee ? merits.find(m => m.instructor == record.id).review_committee.score_teaching : null),
      }, {
        title: 'Service',
        key: 'committee-service',
        align: 'center',
        width: 120,
        render: (text, record, idx) => get_merit_tag(merits.find(m => m.instructor == record.id) && merits.find(m => m.instructor == record.id).review_committee ? merits.find(m => m.instructor == record.id).review_committee.score_service : null),
      }],
    }];
    
    if (review_type == "Dean") {
      columns.push({
        title: 'Dean',
        children: [{
          title: 'Research',
          key: 'dean-research',
          align: 'center',
          width: 120,
          render: (text, record, idx) => get_merit_tag(merits.find(m => m.instructor == record.id) && merits.find(m => m.instructor == record.id).review_dean ? merits.find(m => m.instructor == record.id).review_dean.score_research : null),
        }, {
          title: 'Teaching',
          key: 'dean-teaching',
          align: 'center',
          width: 120,
          render: (text, record, idx) => get_merit_tag(merits.find(m => m.instructor == record.id) && merits.find(m => m.instructor == record.id).review_dean ? merits.find(m => m.instructor == record.id).review_dean.score_teaching : null),
        }, {
          title: 'Service',
          key: 'dean-service',
          align: 'center',
          width: 120,
          render: (text, record, idx) => get_merit_tag(merits.find(m => m.instructor == record.id) && merits.find(m => m.instructor == record.id).review_dean ? merits.find(m => m.instructor == record.id).review_dean.score_service : null),
        }],
      });
       
      columns.push({
        title: 'Score',
        key: 'score',
        align: 'right',
        width: 80,
        render: (text, record, idx) => this.getScore(merits.find(m => m.instructor == record.id)),
      });
    }
      
    columns.push(
      {
        title: 'PDF',
        key: 'file',
        align: 'center',
        width: 80,
        render: (text, record, idx) => merit_pdfs?.filter(
          m => m.faculty == record.id).map(
            file => (<a onClick={() => this.openPDF(file.file, "merit " + file.year + ".pdf")}><Icon type="file-pdf" theme="twoTone" twoToneColor="#eb2f96" /></a>))
      }, {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      width: 80,
      render: (text, record, idx) => {
        const merit = merits.find(m => m.instructor == record.id);
        const disabled = false; // review_type == "Dean" && merit.review_committee == null;
        const menu = 
          <Menu>
            <Menu.Item><a onClick={ () => this.setState({ selected_merit: merit }) }>{ merit && merit[review_key] ? "Edit review" : "Enter review" }</a></Menu.Item>
            {review_type == "Dean" &&
            <Menu.Item><a onClick={() => this.toggleModal( record.id, true)} >Upload PDF</a></Menu.Item>
            }
          </Menu>;
        return disabled ? ( <Tooltip title="No underlying review exists">{ [" Actions ", <Icon key="mine" type="down" />] }</Tooltip> ): ( 
          <Dropdown overlay={ menu }>
            <a className="ant-dropdown-link">
              <div style={{ width: "75px" }}>Actions <Icon key="mine" type="down" /></div>
            </a>
          </Dropdown> );
        },
    });
      
    const tenure_track = this.instructortype_list().filter(t => t.mytype == "Tenured/Tenure-Track")[0];
    
    const title = this.getMeritYear() + " " + this.props.review_type + " Merit Review " + ( selected_merit ? this.print_full_instructor(selected_merit.instructor) : "" );

    return (
        <Content {...this.props} title={ title } breadcrumbs={ [{ link: "/faculty", text: "Faculty" }, { text: "Merit" }] }>
        <p>The Khoury College Merit process is an annual process that is used as input to the annual evaluation performed each year by the Dean. The process was proposed, voted on, and approved by the faculty; it is now a part of the College By-Laws. You can see an overview of the process <a target="_blank" href="https://khoury.northeastern.edu/faculty/khoury-bylaws/blob/master/ttt/merit.md"> on the College GitHub</a>.  In brief, the faculty fill out the form below, and the Merit Committee then reviews the faculty entry and makes any adjustments it feels are necessary. Finally, the Dean reviews the faculty and Merit Committee entries and makes any adjustments; the result is the final score for the faculty member.</p>
        <Modal
          title={"Upload Non-Khoury Interdisciplinary Review for " + this.print_full_instructor(faculty_modal)}
          visible={visible}
          onCancel={() => this.toggleModal(null, false)}
          footer={null}
          width={800}
        >
          <MeritPDF
          {...this.props}
          selectedFaculty={faculty_modal}
          getMerit={this.getMerit}
          handleDelete={this.handleDelete}
          meritYear={meritYear}
          selectedMerit={selected_merit}
          updateState={this.updateState}
          merit_pdfs={merit_pdfs}
          endpoint_file_upload={this.state.endpoint_file_upload}
          />
        </Modal>
        { alert }
        
        <p> </p>

        { loading ? <Spin tip="Loading merit data" /> : selected_merit ? (
            <MeritForm {...this.props} merit={ selected_merit } review_type={ review_type } sections={ sections.filter(s => s.instructors.includes(selected_merit.instructor)) } students={ students } handleCreateUpdate={ this.handleCreateUpdate } wrappedComponentRef={ this.saveFormRef } disabled={ disabled } return_link={ () => this.setState({ selected_merit: null }) } merit_pdfs={merit_pdfs}/>
          ) : this.instructorrank_list().filter(r => r.subtype.mytype.id == tenure_track.id).map(r => (
          <React.Fragment key={ r.id }>
            <Divider key={ "div-" + r.id } orientation="left">{ this.print_instructorrank(r.id) }</Divider>
            <Table {...this.props} 
              dataSource={ this.instructor_list().filter(c => this.get_rank_from_history(c.ranks, this.get_semester(semesters[0])) == r.id && this.get_college(this.get_from_history(c.ranks, this.get_semester(semesters[0])).tenure_home).code == "CS") } 
              columns={ columns } 
              bordered={ false } 
              pagination={ false } 
              size="small" 
              rowKey="id" 
              key={ "table-" + r.id } 
            />
          
          <React.Fragment></React.Fragment>
          </React.Fragment>
        )) } 

        
      </Content>
    );
  }
}

class MeritStaffList extends AppComponent {
  state = {
    endpoint: "/api/merit/staff/",
    merits: [],
    loading_merit: true,
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.semester != this.props.semester) {
      this.getData();
    }
  }
  
  getData = () => {
    this.doGet(this.state.endpoint + "?year=" + this.getMeritYear(), data => this.setState({ merits: data, loading_merit: false }));
  }

  getMeritYear = () => this.calendar_year(this.props.semesters[0]);

  render() {
    const { review_type, semesters } = this.props;
    const { merits, loading_merit } = this.state;

    const tenure_track = this.instructortype_list().filter(t => t.mytype == "Tenured/Tenure-Track")[0];

    const disabled = this.getMeritYear() != moment().format('YYYY')-1;
    const alert = this.getMeritYear() >= moment().format('YYYY') ?  ( <Alert message="Year not yet started" description={ "The " + this.getMeritYear() + " year has not started yet, so you cannot submit the staff data just yet." } type="warning" showIcon /> ) : this.getMeritYear() < moment().format('YYYY')-1 ? ( <Alert message="Year now archived" description={ "The " + this.getMeritYear() + " year is now archived; you may view but not edit the staff data." } type="warning" showIcon /> ) : null;

    const columns = [{
      title: 'Name',
      key: 'name',
      render: (text, record, idx) => this.link_full_instructor(record.id),
    }, {
      title: 'Submitted Satisfactory Grants?',
      key: 'grants',
      align: 'center',
      width: 200,
      render: (text, record, idx) => (
          <Preference {...this.props}
                      value={ merits.find(m => m.instructor == record.id) }
                      editable={ !disabled }
                      onChange={ this.getData }
                      endpoint="/api/merit/staff/"
                      defaultValue={ null }
                      fieldName="submitted_grants_satisfactory"
                      addNewData={ (data) => { data["instructor"] = record.id; data["year"] = this.getMeritYear(); } }
                      options={ [{ id: false, text: "No"}, {id: true, text: "Yes" }] } />        
      ),
    }, {
      title: 'Lead PI on Large Grant?',
      key: 'pi',
      align: 'center',
      width: 200,
      render: (text, record, idx) => (
          <Preference {...this.props}
                      value={ merits.find(m => m.instructor == record.id) }
                      editable={ !disabled }
                      onChange={ this.getData }
                      endpoint="/api/merit/staff/"
                      defaultValue={ null }
                      fieldName="lead_pi_exemplary"
                      addNewData={ (data) => { data["instructor"] = record.id; data["year"] = this.getMeritYear(); } }
                      options={ [{ id: false, text: "No"}, {id: true, text: "Yes" }] } />        
      ),
    }];
        
    return (
        <Content {...this.props} title={ "Staff Merit Data for " + this.getMeritYear() } breadcrumbs={ [{ link: "/staff", text: "Staff" }, { text: "Merit" }] }>
        <p>The Khoury College Merit process is an annual process that is used as input to the annual evaluation performed each year by the Dean.  This process needs input on grants data from the staff on two questions about the grant activity for each faculty member. Specifically:
        <ol>
          <li>During the calendar year {this.getMeritYear()} and the previous year, has the facutly member submitted submitted grant proposals that would cumulatively provide at least 3 semesters of PhD student support per year?</li>
          <li>During the calendar year {this.getMeritYear()}, has the facutly member been the Lead PI on a newly awarded, multi-million dollar grant?</li>
        </ol>
        For each question, please enter "Yes" or "No" in the form below.</p>
        
        { alert }
        
        <p> </p>

        { loading_merit ? <Spin tip="Loading merit data" /> : 
          this.instructorrank_list().filter(r => r.subtype.mytype.id == tenure_track.id).map(r => (
            <React.Fragment key={ r.id }>
              <Divider key={ "div-" + r.id } orientation="left">{ this.print_instructorrank(r.id) }</Divider>
              <Table {...this.props} dataSource={ this.instructor_list().filter(c => this.get_rank_from_history(c.ranks, this.get_semester(semesters[0])) == r.id && this.get_college(this.get_from_history(c.ranks, this.get_semester(semesters[0])).tenure_home).code == "CS") } columns={ columns } bordered={ false } pagination={ false } size="small" rowKey="id" key={ "table-" + r.id } />
            </React.Fragment>              
          ))              
        } 

        
      </Content>
    );
  }
}

export { Merit, MeritStaffList ,MeritList };