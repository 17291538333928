import React, { Component } from "react";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom";

import Root from "./components/App";
import "./style.scss";

Sentry.init({
    dsn: "https://6b8362820aed4a659c7284bf6567e76d@sentry.khoury.northeastern.edu/9",
    replaysSessionSampleRate: 0.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.Replay()],
    release: "1.7.14",
  });

ReactDOM.render(<Root />, document.getElementById("app"));
